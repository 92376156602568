import { ErrorComponent } from '@/components/misc/error-component';
import type { ErrorComponentProps } from '@tanstack/react-router';

function ErrorPage(props: ErrorComponentProps) {
  const error = props.error;

  const status = (error as { status?: number } | undefined)?.status; //TODO: fix this, no longer valid after switching to tanstack

  if (status === 404) {
    return <div>This page doesn&apos;t exist.</div>;
  }

  if (status === 401) {
    return <div>You aren&apos;t authorized to see this page.</div>;
  }

  if (status === 503) {
    return <div>Server error occurred.</div>;
  }

  if (status === 418) {
    return <div>🫖</div>;
  }

  return <ErrorComponent header="Something went wrong" />;
}

export default ErrorPage;
