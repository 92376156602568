import { useContextAndErrorIfNull } from '@/lib/utils';
import { createContext } from 'react';

export const SearchStateContext = createContext<
  [string, (val: string) => void] | null
>(null);

export const useSearchState = () => {
  const searchState = useContextAndErrorIfNull(SearchStateContext);
  return searchState;
};
