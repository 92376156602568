import { oauthProviders } from '@/lib/firebase';
import { cn } from '@/lib/utils';
import { useFeatureFlag } from '@/stores/feature-flag-store';

import type { useAuthAndNavigate } from '@/hooks/login-logout.hook';
import { Button } from '../ui/button';

export function AuthFormOauthButtons({
  className,
  isPending,
  label = 'sign_in',
  onSubmit,
}: {
  className?: string;
  isPending: boolean;
  onSubmit: ReturnType<typeof useAuthAndNavigate>['onSubmit'];
  label?: 'sign_in' | 'signup' | 'continue_with';
}) {
  const [showOauthButtons] = useFeatureFlag('SHOW_OAUTH_BUTTONS');

  if (!showOauthButtons) return null;

  const message =
    label == 'continue_with'
      ? 'Continue with'
      : label == 'signup'
        ? 'Sign up'
        : 'Sign in';

  return (
    <>
      {oauthProviders.map((provider) => (
        <Button
          key={provider.label}
          type="button"
          disabled={isPending}
          className={cn(
            'flex h-[52px] gap-4 rounded-full text-base font-[600] leading-5',
            isPending ? 'cursor-wait' : 'cursor-pointer',
            className,
          )}
          size="lg"
          onClick={() => {
            onSubmit({ oauthProvider: provider.provider });
          }}
          variant="outline"
        >
          <provider.Icon className="size-6" />
          {message} {provider.label}
        </Button>
      ))}
    </>
  );
}
