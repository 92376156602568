import { ErrorComponent } from '@/components/misc/error-component';
import { Providers } from '@/components/misc/providers/providers';
import ErrorPage from '@/components/pages/other/error-page';
import { createRootRoute, Outlet } from '@tanstack/react-router';

export const Route = createRootRoute({
  component: () => (
    <>
      <Providers>
        <Outlet />
      </Providers>
    </>
  ),
  errorComponent: ErrorPage,
  notFoundComponent: () => (
    <ErrorComponent header="Page not found" promptRefresh />
  ),
});
