import { ForceToPlanPageDialog } from '@/components/dialogs/force-to-plan-page-dialog';
import { ModalCmdK } from '@/components/dialogs/modal-cmd-k';
import { UploadDialogsProvider } from '@/components/misc/providers/upload-dialog-providers';
import { NavBar } from '@/components/nav-bar';
import { PlayBar } from '@/components/play-bar';
import { Dialog } from '@/components/ui/dialog';
import { useIsInvalidSubscription } from '@/services/subscription-service';
import { isUserLoggedIn } from '@/services/user-service';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import {
  Navigate,
  Outlet,
  createFileRoute,
  useMatchRoute,
} from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_dashboard')({
  component: Dashboard,
});

function Dashboard() {
  const isSubscriptionInvalid = useIsInvalidSubscription();

  const matchRoute = useMatchRoute();
  const isPlanPageActive = matchRoute({ to: '/plan' }) as boolean; //TODO: shouldn't need cast

  const [forceResubscribe] = useFeatureFlag('FORCE_RESUBSCRIBE_DIALOG');
  const shouldShowBlockingDialog =
    isSubscriptionInvalid && !isPlanPageActive && forceResubscribe;

  if (!isUserLoggedIn()) return <Navigate to="/login" />;

  return (
    <div className="flex size-full flex-col subpixel-antialiased">
      <div className="flex grow overflow-hidden">
        <NavBar />
        <UploadDialogsProvider>
          {!shouldShowBlockingDialog && <ModalCmdK />}
          <main className="h-full grow">
            <Dialog open={shouldShowBlockingDialog}>
              <Outlet />
              <ForceToPlanPageDialog />
            </Dialog>
          </main>
        </UploadDialogsProvider>
      </div>
      <PlayBar />
    </div>
  );
}
