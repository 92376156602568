import { logger } from '@/lib/logger';
import { envString } from '@/lib/utils';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';

export const ampExperiment = (() => {
  if (import.meta.env.SSR) return null;
  try {
    void amplitude
      .init(import.meta.env.VITE_AMPLITUDE_API_KEY as string, {
        defaultTracking: true,
      })
      .promise.then(() => {
        const deviceId = amplitude.getDeviceId();
        console.log('Amplitude device id:', deviceId);
        if (import.meta.env.SSR) return;
        (window as { ampDeviceId?: string | undefined }).ampDeviceId = deviceId; // Expose for debugging
      });
    if (import.meta.env.VITE_AMPLITUDE_DEPLOYMENT_KEY as string | undefined) {
      const experiment = Experiment.initializeWithAmplitudeAnalytics(
        import.meta.env.VITE_AMPLITUDE_DEPLOYMENT_KEY as string,
        {
          debug: import.meta.env.DEV || import.meta.env.MODE === 'staging',
        },
      );
      void experiment.start().catch((error: unknown) => {
        console.error('Error starting amplitude experiment:', error);
      });
      return experiment;
    }
  } catch (error) {
    console.error('Error initializing amplitude experiment:', error);
    return null;
  }
})();

const default_event_properties = {
  category: 'react_web_app',
  environment: envString,
  platform: 'web',
};

export const identifyLogUser = ({
  userId,
  email,
}: {
  userId: string;
  email: string;
}) => {
  try {
    logger.log('identifyUser:', userId);
    amplitude.setUserId(userId);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('user_id', userId);
    identifyEvent.set('email', email);
    amplitude.identify(identifyEvent);
  } catch (error) {
    logger.error('Error identifying user:', error);
  }
};

type AmpEvent =
  | 'app_opened'
  | 'upload_audio_interaction'
  | 'started_playing_audio'
  | 'stopped_playing_audio'
  | 'playback_controls_interaction'
  | 'set_playback_speed'
  | 'set_playback_volume'
  | 'skip_to_chapter'
  | 'note_pad_interaction'
  | 'delete_account_clicked'
  | 'cancel_subscription_clicked'
  | 'login_success'
  | 'change_voice_preference'
  | 'back2school_checkout_attempt'
  | 'back2school_checkout_error';

const trackLog = (
  event_type: AmpEvent,
  event_properties: Record<string, unknown>,
) => {
  try {
    if (import.meta.env.DEV) {
      logger.log(
        'Skipping tracking event in dev mode:',
        event_type,
        event_properties,
      );
      return;
    }
    logger.log('Event: ', event_type, event_properties);
    amplitude.track({
      event_type,
      event_properties: {
        ...default_event_properties,
        ...event_properties,
      },
    });
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

export const flushAmplitudeEvents = () => amplitude.flush();

export const trackLogAppOpened = () => {
  trackLog('app_opened', {});
};

type FileInfo = {
  name: string;
  size: number;
  type: string;
  lastModified: number;
};
export const trackLogUploadAudioInteraction = (props: {
  location:
    | 'nav_side_bar_collapsed_dropzone'
    | 'nav_side_bar_dropzone'
    | 'upload_file_dialog'
    | 'link_dialog'
    | 'app_bar_btn_drop_down'
    | 'forward_email_dialog'
    | 'web_extension';
  interaction:
    | 'file_dropzone'
    | 'link_pasted'
    | 'link_submitted'
    | 'dialog_opened'
    | 'drop_down_opened'
    | 'forward_email_copied';
  files?: FileInfo[];
  url?: string;
}) => {
  trackLog('upload_audio_interaction', props);
};

export const trackLogStartedPlayingAudio = (props: {
  audio_item_id: string;
  chapter_id?: string;
  chapter_title?: string;
  listen_session_id: string;
  playback_rate: number;
  audio_duration_sec: number;
  starting_position_sec?: number;
}) => {
  trackLog('started_playing_audio', props);
};

export const trackLogStoppedPlayingAudio = (props: {
  audio_item_id: string;
  chapter_id?: string;
  chapter_title?: string;
  listen_session_id: string;
  playback_rate: number;
  audio_duration_sec?: number;
  real_world_duration_sec: number;
  rate_modified_duration_sec: number;
}) => {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.add('total_listen_time_sec', props.real_world_duration_sec);
  identifyEvent.add(
    'total_rate_modified_listen_time_sec',
    props.rate_modified_duration_sec,
  );
  amplitude.identify(identifyEvent);
  trackLog('stopped_playing_audio', props);
};

export const trackLogPlaybackControlsInteraction = (props: {
  interaction: 'play' | 'skip_backward' | 'skip_forward' | 'pause' | 'seek';
}) => {
  trackLog('playback_controls_interaction', props);
};

export const trackLogSetPlaybackSpeed = (props: { rate: number }) => {
  trackLog('set_playback_speed', props);
};
export const trackLogSetVolume = (props: { volume: number }) => {
  trackLog('set_playback_volume', props);
};

export const trackLogSkipToChapter = (props: { chapter_name: string }) => {
  trackLog('skip_to_chapter', props);
};

export const trackLogNotePadInteraction = (props: {
  interaction: 'note_added' | 'note_deleted';
}) => {
  trackLog('note_pad_interaction', props);
};

export const trackLogDeleteAccountClicked = () => {
  trackLog('delete_account_clicked', {});
};

export const trackLogCancelSubscriptionClicked = () => {
  trackLog('cancel_subscription_clicked', {});
};

export const trackLogLoginSuccess = () => {
  trackLog('login_success', {});
};

export const trackLogChangeVoicePreference = (props: {
  voice_preference: string;
}) => {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('voice_preference', props.voice_preference);
  amplitude.identify(identifyEvent);
  trackLog('change_voice_preference', props);
};

export const trackLogBack2SchoolCheckoutAttempt = () => {
  trackLog('back2school_checkout_attempt', {});
};

export const trackLogBack2SchoolCheckoutError = (error: Error) => {
  trackLog('back2school_checkout_error', { error: error.message });
};
