import { useLogoutAndClearCache } from '@/hooks/login-logout.hook';
import { logger } from '@/lib/logger';
import { useUserStore } from '@/services/user-service';
import { useMutation } from '@tanstack/react-query';
import { identifyLogUser } from './analytics-service';

import { auth } from '@/lib/firebase';
import { signInWithCustomToken } from 'firebase/auth';
import {
  deleteAccountReq,
  getUserSummaryReq,
  loginReq,
  postCancelSurveyReq,
  registerUserReq,
  type LoginDTO,
  type UserSummaryDTO,
} from './api-service';
import { useUserToken, validTokenGuard } from './user-service';

export const useLoginMutation = () => {
  const login = useUserStore((state) => state.login);
  return useMutation({
    retry: false,
    mutationFn: async (
      params:
        | { email: string; password: string }
        | { firebase_id_token: string },
    ): Promise<UserSummaryDTO | LoginDTO> => {
      if ('firebase_id_token' in params) {
        return getUserSummaryReq(params.firebase_id_token);
      }
      const loginResult = await loginReq(params.email, params.password);
      if (auth.currentUser != null) await auth.signOut();
      if (loginResult.firebase_custom_token)
        await signInWithCustomToken(auth, loginResult.firebase_custom_token);
      return loginResult;
    },
    onSuccess: (rawData, params) => {
      logger.log('Login successful');
      const id = 'id' in rawData ? rawData.id : rawData._id;
      login({
        email: rawData.email,
        token:
          'firebase_id_token' in params
            ? params.firebase_id_token
            : (rawData as LoginDTO).Authorization,
        userId: id,
      });
      identifyLogUser({
        email: rawData.email,
        userId: id,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useRegisterMutation = () => {
  const login = useUserStore((state) => state.login);
  return useMutation({
    mutationFn: (params: Parameters<typeof registerUserReq>[0]) =>
      registerUserReq(params),
    onSuccess: (data, params) => {
      logger.log('Registration successful');
      login({
        email: data.email,
        token:
          'firebase_id_token' in params && params.firebase_id_token
            ? params.firebase_id_token
            : data.Authorization,
        userId: data._id,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useDeleteAccountMutation = () => {
  const logout = useLogoutAndClearCache();
  const { token } = useUserToken();
  const email = useUserStore((state) => state.user)?.email;

  return useMutation({
    mutationFn: async ({
      cancelReason,
      likedFeatures,
    }: {
      cancelReason: string;
      likedFeatures: string;
    }) => {
      void postCancelSurveyReq(validTokenGuard(token), {
        email: validTokenGuard(email),
        is_user_deleted: false,
        user_cancel_reason: cancelReason,
        user_liked_features: likedFeatures,
      });
      await deleteAccountReq(validTokenGuard(token));
    },
    onSuccess: () => {
      logger.log('Delete account successful');
      logout();
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
