import { isUserLoggedIn } from '@/services/user-service';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

const loginSearchParamsSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/continue')({
  validateSearch: (search) => loginSearchParamsSchema.parse(search),
  beforeLoad: () => {
    if (isUserLoggedIn()) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error -- This is a redirect
      throw redirect({
        to: '/back2school',
      });
    }
  },
});
