import ReferIcon from '@/assets/icons/refer.svg?react';

import { useState } from 'react';
import { DialogContent } from '../ui/dialog';
import { Input } from '../ui/input';
import { Label } from '../ui/label';

import { cn } from '@/lib/utils';
import { trackLogUploadAudioInteraction } from '@/services/analytics-service';
import { Copy } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { TooltipButton } from '../ui/tooltip-button';

export function ForwardEmailDialog() {
  const [showCopied, setShowCopied] = useState(false);

  return (
    <DialogContent className="flex max-h-[90%] flex-col items-center rounded-2xl">
      <ForwardEmailDialog.Icon />
      <h3 className="text-lg font-[700]">Forward an email</h3>
      <p className="text-center">
        We&apos;ll turn emails and newsletters into audio. <br /> Attachments
        aren&apos;t supported yet.
      </p>
      <div className="flex items-end space-x-2">
        <div className="grid flex-1 gap-2">
          <Label htmlFor="link">Email to</Label>
          <Input
            className="text-foreground"
            id="link"
            defaultValue="listen@mail.listening.io"
            readOnly
            onCopy={() => {
              trackLogUploadAudioInteraction({
                interaction: 'forward_email_copied',
                location: 'forward_email_dialog',
              });
            }}
          />
        </div>
        <Tooltip open={showCopied}>
          <TooltipTrigger asChild>
            <TooltipButton
              toolTipContent="Copy"
              size="sm"
              className="mb-0.5 px-3"
              onClick={async () => {
                trackLogUploadAudioInteraction({
                  interaction: 'forward_email_copied',
                  location: 'forward_email_dialog',
                });
                await navigator.clipboard.writeText('listen@mail.listening.io');
                setShowCopied(true);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setShowCopied(false);
              }}
            >
              <Copy className="size-4" />
            </TooltipButton>
          </TooltipTrigger>
          <TooltipContent>Copied!</TooltipContent>
        </Tooltip>
      </div>
    </DialogContent>
  );
}

ForwardEmailDialog.Icon = ({ className }: { className?: string }) => (
  <div className="rounded-full bg-[#FCE7F780] p-[4.5px] dark:bg-[#FFFFFF0D]">
    <div className="rounded-full bg-[#FCE7F7] p-3 dark:bg-[#FFFFFF0D]">
      <ReferIcon className={cn('size-8 text-[#C89ABC]', className)} />
    </div>
  </div>
);
