import CoverImgPlaceholderIcon from '@/assets/icons/cover-image-place-holder.svg?react';
import { getBigBlurImageSrc } from '@/components/audio/blur-thumbnail';
import Spinner from '@/components/misc/spinner';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { Img } from 'react-image';

export function CoverImgWithBackgroundOld({
  imgSrc,
  className,
  imgClassName,
}: {
  imgSrc?: string | null;
  className?: string;
  imgClassName?: string;
}) {
  return (
    <div
      className={cn(
        'flex aspect-square w-full shrink-0 flex-col items-center overflow-clip bg-gradient-to-r from-cyan-500 to-blue-500',
        className,
      )}
    >
      <Img
        // TODO: Pick a default
        src={imgSrc ?? ''}
        className={cn('mt-4 w-5/6 rounded-md', imgClassName)}
        decode={false}
        loader={
          <Skeleton className="mt-4 h-full w-5/6 rounded-md rounded-b-none" />
        }
      />
    </div>
  );
}

export function CoverImgWithBackgroundNew({
  coverImg,
  title,
  size,
  isProcessing,
}: {
  coverImg?: string | null;
  title?: string | null;
  size?: 'sm' | 'lg';
  isProcessing?: boolean;
}) {
  const badSrc = !coverImg;
  const [isLoading, setLoading] = useState(!badSrc);
  const [isError, setError] = useState(badSrc);

  useEffect(() => {
    setLoading(!badSrc);
    setError(badSrc);
  }, [badSrc]);

  const imgClassName = cn(
    'absolute top-0 z-10 min-h-full w-full rounded-t-xl',
    size == 'sm' && 'rounded-sm',
  );

  return (
    <div
      className={cn(
        'relative flex size-full flex-col items-center overflow-hidden rounded-[20px] px-2 pb-0 pt-3',
        size == 'sm' && 'rounded-lg p-[2.5px] pb-0',
      )}
    >
      <img
        className="absolute top-0 size-[105%] dark:opacity-55"
        src={getBigBlurImageSrc(title)}
        alt="Blur background"
      />
      <div
        className={cn(
          'relative flex size-full flex-col items-center p-2 pb-0',
          size == 'sm' && 'p-[1.5px] pb-0',
        )}
      >
        {!isLoading && !isError && (
          <div
            className={cn(
              'absolute top-0 size-full rounded-t-2xl bg-white opacity-50',
              size == 'sm' && 'rounded-t-md',
            )}
          />
        )}
        {isProcessing ? (
          <div
            className={cn(
              'flex size-full items-center justify-center',
              size == 'sm' ? 'pb-1' : 'pb-5',
            )}
          >
            <Spinner className="size-10 opacity-70" />
          </div>
        ) : (
          <div className="relative flex size-full flex-col items-center overflow-clip">
            {isLoading && <Skeleton className={imgClassName} />}
            {isError ? (
              <CoverImgPlaceholderIcon
                className={cn(
                  'absolute size-24',
                  size == 'sm' ? 'mt-1 size-8' : 'mt-5',
                )}
              />
            ) : (
              <img
                className={imgClassName}
                alt={'Audio Cover'}
                src={coverImg ?? ''}
                onLoad={() => {
                  setLoading(false);
                }}
                onError={() => {
                  setError(true);
                  setLoading(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
