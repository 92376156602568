import blur1Png from '@/assets/imgs/blur-audio-thumbnail/blur1.png';
import blur2Png from '@/assets/imgs/blur-audio-thumbnail/blur2.png';
import blur3Png from '@/assets/imgs/blur-audio-thumbnail/blur3.png';
import blur4Png from '@/assets/imgs/blur-audio-thumbnail/blur4.png';
import blur5Png from '@/assets/imgs/blur-audio-thumbnail/blur5.png';
import { DEFAULT_TITLE } from '@/lib/audio-utils';
import { clamp, cn, stringToNumberHash } from '@/lib/utils';

export function BlurThumbnail({
  preset,
  className,
}: {
  preset: number;
  className?: string;
}) {
  const imgIdx = clamp(Math.abs(preset % 5), 0, 5);
  const imgSrc = [blur1Png, blur2Png, blur3Png, blur4Png, blur5Png][imgIdx];
  return (
    <img
      src={imgSrc}
      alt="Audio Thumbnail"
      className={cn('size-6 rounded-sm', className)}
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components -- don't need fast refresh
export function getBigBlurImageSrc(title?: string | null) {
  const preset = stringToNumberHash(title ?? DEFAULT_TITLE);
  const gallery = Object.values(
    import.meta.glob('@/assets/imgs/blur-audio-big/*.{png,PNG}', {
      eager: true,
      query: '?url',
      import: 'default',
    }),
  ) as unknown as string[];
  const imgIdx = clamp(Math.abs(preset % gallery.length), 0, gallery.length);
  const imgSrc = gallery[imgIdx];
  if (!imgSrc) throw new Error('No image found');
  return imgSrc;
}
