import { create } from 'zustand';

export const envFlags = {
  USE_V2_POST_API: import.meta.env.VITE_USE_V2_POST_API === 'true',
  SHOW_CONTINUE_PAGE: import.meta.env.VITE_SHOW_CONTINUE_PAGE === 'true',
  SHOW_BACK2SCHOOL_PAGE: import.meta.env.VITE_SHOW_BACK2SCHOOL === 'true',
  SHOW_OAUTH_BUTTONS: import.meta.env.VITE_OAUTH_BUTTONS === 'true',
  NEW_VIRTUAL_READ_ALONG_TEXT_VIEW:
    import.meta.env.VITE_NEW_VIRTUAL_READ_ALONG_TEXT_VIEW === 'true',
  SHOW_PDF_READ_ALONG: import.meta.env.VITE_SHOW_PDF_READ_ALONG === 'true',
  SHOW_PLAN_SWITCHER: import.meta.env.VITE_SHOW_PLAN_SWITCHER === 'true',
  FORCE_RESUBSCRIBE_DIALOG:
    import.meta.env.VITE_FORCE_RESUBSCRIBE_DIALOG === 'true',
  SHOW_RESIZABLE_HOME_PAGE:
    import.meta.env.VITE_SHOW_RESIZABLE_HOME_PAGE === 'true',
  SHOW_DELETE_ACCOUNT_OTP_EMAIL:
    import.meta.env.VITE_SHOW_DELETE_ACCOUNT_OTP_EMAIL === 'true',
  SHOW_RECENTLY_PLAYED: import.meta.env.VITE_SHOW_RECENTLY_PLAYED === 'true',
  SHOW_NEW_CATEGORIES: import.meta.env.VITE_SHOW_NEW_CATEGORIES === 'true',
  SHOW_REFER_A_FRIEND: import.meta.env.SHOW_REFER_A_FRIEND === 'true',
  SHOW_FILES_TAB: import.meta.env.VITE_SHOW_FILES_TAB === 'true',
  SHOW_NOTIFICATIONS: import.meta.env.VITE_SHOW_NOTIFICATIONS === 'true',
  SHOW_SHUFFLE: import.meta.env.VITE_SHOW_SHUFFLE === 'true',
  SHOW_LOOP: import.meta.env.VITE_SHOW_LOOP === 'true',
  SHOW_REGISTER: import.meta.env.VITE_SHOW_REGISTER === 'true',
  TANSTACK_QUERY_DEVTOOLS:
    import.meta.env.VITE_TANSTACK_QUERY_DEVTOOLS === 'true',
  TANSTACK_ROUTER_DEVTOOLS:
    import.meta.env.VITE_TANSTACK_ROUTER_DEVTOOLS === 'true',
  MOCK_SUBSCRIPTION_ENDPOINT:
    import.meta.env.VITE_MOCK_SUBSCRIPTION_ENDPOINT === 'true' &&
    import.meta.env.MODE !== 'production',
  FEATURE_FLAG_DEVTOOLS: import.meta.env.VITE_FEATURE_FLAG_DEVTOOLS === 'true',
};
export type FeatureFlag = keyof typeof envFlags;
export type FeatureFlagTag =
  | 'Dev Tool'
  | 'Needs UI Design'
  | 'Needs Backend'
  | 'Needs Frontend'
  | 'Ready For Review'
  | 'In Staging'
  | 'Disabled';

export const featureFlagTagColors: Record<FeatureFlagTag, string> = {
  'Dev Tool': 'bg-purple-200',
  'Needs UI Design': 'bg-yellow-200',
  'Needs Backend': 'bg-red-200',
  'Needs Frontend': 'bg-blue-200',
  'Ready For Review': 'bg-green-200',
  'In Staging': 'bg-green-200',
  Disabled: 'bg-gray-200',
};
type FlagMetadata = {
  description: string;
  tags: FeatureFlagTag[];
};
export const featureFlagMetadata: Record<FeatureFlag, Partial<FlagMetadata>> = {
  USE_V2_POST_API: {
    description: 'Use V2 POST API, should be new llm pipeline',
    tags: ['Ready For Review'],
  },
  SHOW_BACK2SCHOOL_PAGE: {
    description: '/back2school',
    tags: ['Ready For Review'],
  },
  SHOW_CONTINUE_PAGE: {
    description: '/continue page',
    tags: ['Ready For Review'],
  },
  SHOW_OAUTH_BUTTONS: {
    description: 'Social login buttons on login page.',
    tags: ['Ready For Review'],
  },
  NEW_VIRTUAL_READ_ALONG_TEXT_VIEW: {
    description: 'Infinite scroll read along feature.',
    tags: ['Ready For Review'],
  },
  SHOW_PDF_READ_ALONG: {
    description: 'PDF read along feature.',
    tags: ['Needs Frontend'],
  },
  SHOW_PLAN_SWITCHER: {
    description: 'Plan switcher on plan page',
    tags: ['Needs Frontend'],
  },
  FORCE_RESUBSCRIBE_DIALOG: {
    description: 'Force user to resubscribe dialog.',
    tags: ['Needs Frontend'],
  },
  SHOW_RESIZABLE_HOME_PAGE: {
    description: 'Resizable home page layout.',
    tags: ['In Staging', 'Ready For Review'],
  },
  SHOW_DELETE_ACCOUNT_OTP_EMAIL: {
    description: 'OTP email for account deletion.',
    tags: ['Needs Backend'],
  },
  SHOW_NEW_CATEGORIES: {
    description: 'Affects home page layout. UI mocked out.',
    tags: ['Needs Backend'],
  },
  SHOW_NOTIFICATIONS: {
    description: 'App bar icon',
    tags: ['Needs Backend', 'Needs Frontend', 'Needs UI Design'],
  },
  SHOW_REFER_A_FRIEND: {
    description: 'Under user avatar.',
    tags: ['Needs Frontend'],
  },
  SHOW_FILES_TAB: {
    description: 'Only navbar link implemented. Just redirects to home page.',
    tags: ['Needs Frontend'],
  },
  SHOW_RECENTLY_PLAYED: {
    description: 'Must open side bar to see.',
    tags: ['Needs Backend'],
  },
  SHOW_REGISTER: {
    description: 'Soon to be removed. Replaced by staging webflow.',
    tags: ['Dev Tool'],
  },
  SHOW_SHUFFLE: {
    description: 'Shuffle audio button. Works as expected.',
    tags: ['Disabled'],
  },
  SHOW_LOOP: {
    description: 'Loop audio button. Works as expected.',
    tags: ['Disabled'],
  },
  TANSTACK_QUERY_DEVTOOLS: {
    description: 'A dev tool for react-query. Not for production.',
    tags: ['Dev Tool'],
  },
  TANSTACK_ROUTER_DEVTOOLS: {
    description: 'A dev tool for tanstack router. Not for production.',
    tags: ['Dev Tool'],
  },
  FEATURE_FLAG_DEVTOOLS: {
    description: 'This dialog :)',
    tags: ['Dev Tool'],
  },
  MOCK_SUBSCRIPTION_ENDPOINT: {
    description: 'Mock subscription endpoint for dev.',
    tags: ['Dev Tool'],
  },
};

type FeatureFlagStore = {
  flags: typeof envFlags;
  metadata: typeof featureFlagMetadata;
  setFlag: (_flag: FeatureFlag, _value: boolean) => void;
};

export const useFeatureFlagStore = create<FeatureFlagStore>()((set) => ({
  flags: envFlags,
  metadata: featureFlagMetadata,
  setFlag: (flag: FeatureFlag, value: boolean) => {
    set((state) => ({
      ...state,
      flags: {
        ...state.flags,
        [flag]: value,
      },
    }));
  },
}));

export const useFeatureFlag = (flag: FeatureFlag) => {
  const { flags, setFlag } = useFeatureFlagStore();
  return [
    flags[flag],
    (value: boolean) => {
      setFlag(flag, value);
    },
  ] as const;
};
