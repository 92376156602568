import type { paths } from '@/api/v1';
import { useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from './react-use-audio-player/useGlobalAudioPlayer';

const audioDomNode = document.createElement('audio');
audioDomNode.load();

export const DEFAULT_TITLE = 'Untitled';

export type AudioItemMetaData =
  paths['/v2/audio']['get']['responses']['200']['content']['application/json']['audio_conversions'][0];
export type AudioItemDetail =
  paths['/v2/audio/{audio_id}']['get']['responses']['200']['content']['application/json']['audio_conversion'];

export type ChapterMetaData = NonNullable<AudioItemDetail['chapters']>[0];

export type ChapterDetail =
  paths['/v2/chapter/{chapter_id}']['get']['responses']['200']['content']['application/json']['chapter'];

export type AudioItemDTO = {
  title?: string | null;
  author?: string | null;
  text?: string | null;
  tags: TagDTO[];
  description?: string | null;
  status: string;
  dateAdded: string;
  audioConversionID: string;
  audioConversion: AudioConversionDTO;
  audioURL: string;
  cover_img: string;
  updated_at_ms: number;
  language: string;
};

export type TagDTO = {
  label: string;
  color: string;
};

export type AudioConversionDTO = {
  audioURL: string;
  chapters?: ChaptersDTO | null;
  conversionStatus: string;
  conversionTaskId?: string | null;
  conversionURLOriginal: string;
  conversionWordCount?: number | null;
  documentType?: string | null;
  id: string;
  startTime: number;
  updated_at_ms: number;
  voice: string;
  language?: string | null;
};

export type ChaptersDTO = {
  children: ChapterDTO[];
  num_pages: number;
  pdf_title: string;
};

export type ChapterDTO = {
  audio_preview_url: string;
  audio_preview_url_status: string;
  audio_url: string | null;
  audio_url_status: string;
  chapter_id: string;
  children: ChapterDTO[];
  estimated_audio_ms: number;
  size: string;
  title: string;
  word_count: number;
};

export function isChapterBased(
  audio: AudioItemDTO,
): audio is AudioItemDTO & { audioConversion: { chapters: ChaptersDTO } } {
  return (
    audio.audioConversion.chapters != null &&
    audio.audioConversion.chapters.children.length > 0
  );
}

export function isPreview(chapter: ChapterDTO) {
  return chapter.audio_url == null;
}

export function flatChildren(chapters?: ChaptersDTO | null) {
  const flat: ChapterDTO[] = [];
  if (chapters == null) {
    return flat;
  }
  function recurse(chapter: ChapterDTO) {
    flat.push(chapter);
    chapter.children.forEach(recurse);
  }
  chapters.children.forEach(recurse);
  return flat;
}

export function useAudioTimeSec() {
  const frameRef = useRef<number>();
  const [pos, setPos] = useState(0);
  const { getPosition } = useGlobalAudioPlayer();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const animate = () => {
      setPos(getPosition());
      timeoutId = setTimeout(() => {
        frameRef.current = requestAnimationFrame(animate);
      }, 100);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [getPosition]);

  return pos;
}

export function processingItemStatusString(status: string) {
  if (status == 'initializing') return 'Initializing';
  if (status == 'downloadedPDF') return 'Downloaded PDF';
  if (status == 'completedExtraction') return 'Generating Audio';
  if (status == 'Error') return 'An error occurred';
  if (status == 'Error_Not_Enough_Credit') return 'Not enough credit';
  if (status == 'incomplete') return 'In progress';
  if (status == 'complete') return 'Completed';
  if (status == 'deleted') return 'Deleted';
}
export function isProcessingItemStatusInProgress(status?: string) {
  if (!status) return false;
  return (
    status == 'initializing' ||
    status == 'downloadedPDF' ||
    status == 'completedExtraction' ||
    status == 'incomplete'
  );
}

export function getFirstChapter(audio: AudioItemDTO) {
  if (!isChapterBased(audio)) return undefined;
  return audio.audioConversion.chapters.children[0];
}

export function getAudioUrl(audio: {
  audioItem: AudioItemDTO;
  chapter?: ChapterDTO;
}) {
  const { audioItem, chapter } = audio;
  const selectedChapter = chapter ?? getFirstChapter(audioItem);
  if (selectedChapter) {
    return selectedChapter.audio_url ?? selectedChapter.audio_preview_url;
  }
  return audioItem.audioConversion.audioURL;
}

export function setRealAudioDurationMs(
  audio: { audioItem: AudioItemDTO; chapter?: ChapterDTO },
  durationMs: number,
) {
  const audioUrl = getAudioUrl(audio);
  if (durationMs > 0)
    localStorage.setItem(`audio-duration-${audioUrl}`, durationMs.toFixed(0));
}

export function getChapterDurationMs(audio: {
  audioItem: AudioItemDTO;
  chapter?: ChapterDTO;
}) {
  const audioUrl = getAudioUrl(audio);
  const { chapter } = audio;
  return Number(
    localStorage.getItem(`audio-duration-${audioUrl}`) ??
      chapter?.estimated_audio_ms ??
      0,
  );
}

export function getAudioDurationMs(audioItem: AudioItemDTO) {
  const chapters = flatChildren(audioItem.audioConversion.chapters);

  const audioDuration = chapters.reduce(
    (acc, curr) => acc + getChapterDurationMs({ audioItem, chapter: curr }),
    0,
  );
  return audioDuration;
}
