import { cn } from '@/lib/utils';
import Spinner from '../misc/spinner';
import { Button } from '../ui/button';

export function AuthFormSubmitButton({
  className,
  isPending,
  children,
}: {
  isPending: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Button
      type="submit"
      disabled={isPending}
      className={cn(
        'flex h-[52px] gap-2 rounded-full text-base font-[600] leading-5',
        className,
        isPending ? 'cursor-wait' : 'cursor-pointer',
      )}
      size="lg"
    >
      {isPending && <Spinner />}
      {children ?? 'Continue with email'}
    </Button>
  );
}
