import PageIcon from '@/assets/icons/page.svg?react';
import UploadIcon from '@/assets/icons/upload.svg?react';
import { cn } from '@/lib/utils';
import {
  useFileUploadStore,
  useUploadDropzone,
} from '@/services/file-upload-service';
import { FileUploadScrollable } from '../misc/file-upload-scrollable';
import { Button } from '../ui/button';
import { DialogContent } from '../ui/dialog';

export function UploadFileDialog() {
  const uploads = useFileUploadStore((state) => state.uploads);

  const oneSuccessfulUpload = uploads.some((upload) => upload.status == 'done');
  return (
    <DialogContent className="flex h-min max-h-[90%] flex-col items-center gap-2 overflow-auto rounded-2xl p-2 md:p-6">
      <UploadFileDialog.Icon />
      <h3 className="text-lg font-[700]">Upload a file</h3>
      <p className="short:hidden">
        Select a file from your computer or drag here
      </p>
      <UploadDropzoneDialog />
      <FileUploadScrollable location="upload_file_dialog" />
      {oneSuccessfulUpload && (
        <p className="text-center text-secondary-foreground">
          After uploading, it takes minutes to convert to audio. <br />
          Check the status in the Files page.
        </p>
      )}
    </DialogContent>
  );
}

UploadFileDialog.Icon = ({ className }: { className?: string }) => (
  <div className="rounded-full bg-[#E3FDDB]/50 p-[4.5px] dark:bg-[#FFFFFF0D]">
    <div className="rounded-full bg-[#E3FDDB] p-3 dark:bg-[#FFFFFF0D]">
      <PageIcon className={cn('size-8 text-[#079455]', className)} />
    </div>
  </div>
);

function UploadDropzoneDialog() {
  const { getRootProps, getInputProps, isDragActive } = useUploadDropzone({
    showInProgressToast: false,
    location: 'upload_file_dialog',
  });

  return (
    <div className="w-full p-2 outline-none sm:h-52 sm:p-4" {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={cn(
          `flex size-full flex-col items-center justify-evenly rounded-2xl border-4 border-dashed bg-muted px-7 py-3 text-muted-foreground`,
          isDragActive && 'bg-secondary underline',
        )}
      >
        <UploadIcon className="size-6" />
        <strong className="text-center text-xl font-[700]">Upload</strong>
        <p className="pb-1 text-center text-xs font-[500]">Drag and drop</p>
        <Button className="w-full rounded-full">or select file</Button>
      </div>
    </div>
  );
}
