import {
  lastPlayedAudiosFromStorePositions,
  useLastPlayedAudioStore,
} from '@/components/misc/providers/audio-load-provider';
import {
  flatChildren,
  type AudioItemDTO,
  type ChapterDTO,
  type TagDTO,
} from '@/lib/audio-utils';
import { useListAudioQuery } from '@/services/audio-service';
import { useMemo } from 'react';

export const useRecentAudioItems = () => {
  const listAudioQuery = useListAudioQuery();

  const items = listAudioQuery.data?.data ?? [];

  if (items.length === 0) return [];

  const sorted = [...items].sort((b, a) => a.updated_at_ms - b.updated_at_ms);

  return sorted;
};

export const useLastPlayedAudioItems = () => {
  const lastPlayedItems = useLastPlayedAudioStore((state) =>
    lastPlayedAudiosFromStorePositions(state._positions),
  );
  const listAudioQuery = useListAudioQuery();

  const lastPlayedAudios = lastPlayedItems
    .map((lastPlayedItem) => {
      const audioItem = listAudioQuery.data?.data.find(
        (audio) => audio.audioConversionID === lastPlayedItem.audioId,
      );
      const chapters = flatChildren(audioItem?.audioConversion.chapters);
      const chapter = chapters.find(
        (c) => c.chapter_id === lastPlayedItem.chapterId,
      );
      return {
        audioItem,
        lastPlayedItem,
        chapter,
      };
    })
    .filter((audio) => audio.audioItem) as {
    audioItem: AudioItemDTO;
    lastPlayedItem: (typeof lastPlayedItems)[0];
    chapter: ChapterDTO | undefined;
  }[];
  return lastPlayedAudios;
};

export const useUniqueTags = () => {
  const listAudioQuery = useListAudioQuery();

  const tagCounts = useMemo(() => {
    const tags =
      listAudioQuery.data?.data.map((audio) => audio.tags).flat() ?? [];

    const tagStringCounts = tags.reduce((acc, tag) => {
      const key = `${tag.label}-${tag.color}`;
      const existingCount = acc.get(key) ?? 0;
      acc.set(key, existingCount + 1);
      return acc;
    }, new Map<string, number>());

    const tagDTOMap = Array.from(tagStringCounts).reduce(
      (acc, [key, value]) => {
        const [label, color] = key.split('-');
        if (!label || !color) throw new Error('Invalid tag key');
        const tagDTO: TagDTO = { label, color };
        acc.set(tagDTO, value);
        return acc;
      },
      new Map<TagDTO, number>(),
    );

    return tagDTOMap;
  }, [listAudioQuery.data?.data]);

  const uniqueTags = Array.from(tagCounts.keys());
  return { uniqueTags, tagCounts };
};
