import {
  isChapterBased,
  type AudioItemDTO,
  type ChapterDTO,
} from '@/lib/audio-utils';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AudioStoreState = {
  audioItem: AudioItemDTO | null;
  chapter: ChapterDTO | null;
  setAudioItem: (
    audioItem: AudioItemDTO | null,
    chapter?: ChapterDTO | null,
  ) => void;
  setChapter: (chapter: ChapterDTO) => void;
  setShuffle: (shuffle: boolean) => void;
  shuffle: boolean;
};

export const useAudioStore = create<AudioStoreState>()(
  devtools((set) => ({
    audioItem: null,
    chapter: null,
    setAudioItem: (
      audioItem: AudioItemDTO | null,
      chapter?: ChapterDTO | null,
    ) => {
      set({ audioItem });
      if (audioItem == null) {
        set({ chapter: null });
        return;
      }

      if (isChapterBased(audioItem)) {
        const selectedChapter =
          chapter ?? audioItem.audioConversion.chapters.children[0];
        if (!selectedChapter) throw new Error('No chapters found');
        set({ chapter: selectedChapter });
      } else {
        set({ chapter: null });
      }
    },
    setChapter: (chapter: ChapterDTO) => {
      set({ chapter });
    },
    setShuffle: (shuffle: boolean) => {
      set({ shuffle });
    },
    shuffle: false,
  })),
);

export const fontStyleLabelToClassName: Record<
  (typeof fontStyles)[number],
  string
> = {
  Poppins: "font-['Poppins']",
  Besley: "font-['Besley_Variable']",
  'Roboto Serif': "font-['Roboto_Serif_Variable']",
  'Playfair Display': "font-['Playfair_Display_Variable']",
  Quicksand: "font-['Quicksand_Variable']",
};

export const fontStyles = [
  'Poppins',
  'Besley',
  'Roboto Serif',
  'Playfair Display',
  'Quicksand',
] as const;

export const fontSizes = ['Small', 'Medium', 'Large'] as const;

type FontSettings = {
  size: (typeof fontSizes)[number];
  style: (typeof fontStyles)[number];
};

type LocalPrefsState = {
  setPlaybackRate: (playbackRate: number) => void;
  playbackRate: number;
  setVolume: (volume: number) => void;
  volume: number;
  setFontSettings: (settings: FontSettings) => void;
  fontSettings: FontSettings;
  reset: () => void;
  readAlongViewer: 'normal' | 'pdf';
  setReadAlongViewer: (viewer: 'normal' | 'pdf') => void;
  pdfScale: number;
  setPdfScale: (scale: number) => void;
  autoScroll: boolean;
  setAutoScroll: (autoScroll: boolean) => void;
};

export const useLocalPrefsStore = create<LocalPrefsState>()(
  persist(
    (set) => ({
      setPlaybackRate: (playbackRate: number) => {
        set({ playbackRate });
      },
      playbackRate: 1,
      setVolume: (volume: number) => {
        set({ volume });
      },
      volume: 1,
      setFontSettings: (settings: FontSettings) => {
        set({ fontSettings: settings });
      },
      fontSettings: {
        size: 'Medium',
        style: 'Poppins',
      },
      reset: () => {
        set({
          playbackRate: 1,
          volume: 1,
          fontSettings: {
            size: 'Medium',
            style: 'Poppins',
          },
        });
      },
      readAlongViewer: 'pdf',
      setReadAlongViewer: (viewer: 'normal' | 'pdf') => {
        set({ readAlongViewer: viewer });
      },
      pdfScale: 1,
      setPdfScale: (scale: number) => {
        set({ pdfScale: scale });
      },
      autoScroll: false,
      setAutoScroll: (autoScroll: boolean) => {
        set({ autoScroll });
      },
    }),
    {
      name: 'audio-prefs-storage',
    },
  ),
);
