export function parseUtmParams(search: string) {
  const urlParams = new URLSearchParams(search);
  let referralAndUtmParams = Array.from(urlParams)
    .filter(
      ([key]) =>
        key.startsWith('utm') ||
        ['deep_link_value', 'grsf', 'gclid', 'fbclid'].includes(key),
    )
    .reduce((obj: Record<string, string>, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  const growsurf_referrer_id =
    referralAndUtmParams.grsf ?? referralAndUtmParams.deep_link_value;
  if (growsurf_referrer_id)
    referralAndUtmParams = {
      ...referralAndUtmParams,
      growsurf_referrer_id,
    };
  if (referralAndUtmParams.gclid) {
    referralAndUtmParams.google_click_id = referralAndUtmParams.gclid;
    delete referralAndUtmParams.gclid;
  }
  if (referralAndUtmParams.fbclid) {
    referralAndUtmParams.facebook_click_id = referralAndUtmParams.fbclid;
    delete referralAndUtmParams.fbclid;
  }
  const fbcCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith('_fbc='));
  if (fbcCookie) {
    const cookieValue = fbcCookie.split('=')[1];
    if (cookieValue) referralAndUtmParams.facebook_click_cookie = cookieValue;
  }

  const fbpCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith('_fbp='));
  if (fbpCookie) {
    const cookieValue = fbpCookie.split('=')[1];
    if (cookieValue) referralAndUtmParams.facebook_pixel_cookie = cookieValue;
  }

  return referralAndUtmParams;
}

export function getUtmParams(): Record<string, string> {
  if (import.meta.env.SSR) return {};
  const referralAndUtmParams = parseUtmParams(window.location.search);
  console.log('UTM params: ', referralAndUtmParams);
  return referralAndUtmParams;
}
