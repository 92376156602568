import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { useContext, type Context } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

export const CHROME_EXTENSION =
  import.meta.env.VITE_CHROME_EXTENSION === 'true';
export const IS_TAURI = '__TAURI__' in window;

export const envString = `${import.meta.env.MODE}${CHROME_EXTENSION ? '-chrome-extension' : ''}${IS_TAURI ? `-tauri` : ''}`;

export const useContextAndErrorIfNull = <ItemType>(
  context: Context<ItemType | null>,
): ItemType => {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw Error('Context has not been Provided!');
  }
  return contextValue;
};

export function raiseIfFalsy<T>(
  value: T | undefined,
  message?: string,
): NonNullable<T> {
  if (!value) {
    throw new Error(message ?? 'Unexpected falsy value');
  }
  return value;
}

export function delayMs(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const lastOrUndefined = <T>(arr: T[]) => arr[arr.length - 1];

export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const waitForElement = async (selector: string, timeout = 5000) => {
  const start = Date.now();
  while (Date.now() - start < timeout) {
    const element = document.querySelector(selector);
    if (element) return element;
    await delayMs(100);
  }
  return null;
};

export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function stringToNumberHash(str: string) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
}

export function formatTime(seconds: number): string {
  const date = new Date(0);
  date.setSeconds(seconds);

  return format(date, 'mm:ss');
}

export type ComponentWithClassName = ({
  className,
}: {
  className?: string;
}) => React.ReactNode;
