import { refreshFirebaseTokenIfNeeded } from '@/lib/firebase';
import { logger } from '@/lib/logger';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  getUserPreferencesReq,
  getUserProfileReq,
  patchUserProfileReq,
  updateUserPreferencesReq,
  type UserPreferencesDTO,
  type UserProfileDTO,
} from './api-service';

type User = {
  email: string;
  token: string;
  userId: string;
};

type UserStoreState = {
  user: User | null;
  login: (user: User) => void;
  logout: () => void;
};

export const useUserStore = create<UserStoreState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        login: (user) => {
          logger.log('saving user to user store cache');
          useUserStore.persist.clearStorage();
          Sentry.setUser({ email: user.email });
          set({ user });
        },
        logout: () => {
          logger.log('clearing user from user store cache');
          set({ user: null });
          useUserStore.persist.clearStorage();
          localStorage.clear();
        },
      }),
      {
        name: 'user-storage',
      },
    ),
  ),
);

export const isUserLoggedIn = () => {
  const user = useUserStore.getState().user;
  return user?.token && user.userId ? user.token : null;
};

export const useUserToken = () => {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.login);
  const token = user?.token;
  if (user)
    refreshFirebaseTokenIfNeeded((newToken) => {
      setUser({ ...user, token: newToken });
    }, token);
  return { token, isAuthorized: !!token };
};

export function validTokenGuard(token: string | undefined): string {
  if (!token) throw new Error('User not logged in');
  return token;
}

export const usePatchUserProfileMutation = () => {
  const queryClient = useQueryClient();

  const { token } = useUserToken();
  return useMutation({
    mutationFn: (changes: Partial<UserProfileDTO>) => {
      if (!token) throw new Error('User not logged in');
      return patchUserProfileReq(token, changes);
    },
    onSuccess: (_, changes) => {
      queryClient.setQueriesData(
        { queryKey: [getUserProfileReq.name] },
        (old: { data: UserProfileDTO } | undefined) =>
          old
            ? {
                ...old,
                data: {
                  ...old.data,
                  ...changes,
                },
              }
            : undefined,
      );
      return queryClient.invalidateQueries({
        queryKey: [getUserProfileReq.name],
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useUserProfileQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getUserProfileReq.name, token],
    queryFn: () => getUserProfileReq(validTokenGuard(token)),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useUserPreferencesQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getUserPreferencesReq.name, token],
    queryFn: () => getUserPreferencesReq(validTokenGuard(token)),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useUpdateUserPreferencesMutation = () => {
  const queryClient = useQueryClient();
  const { token } = useUserToken();
  return useMutation({
    mutationFn: (updates: Partial<UserPreferencesDTO>) =>
      updateUserPreferencesReq(validTokenGuard(token), updates),
    onSuccess: (_, preferences) => {
      logger.log('Successfully updated user preferences', preferences);
      queryClient.setQueriesData(
        { queryKey: [getUserPreferencesReq.name] },
        (old: { data: UserPreferencesDTO } | undefined) =>
          old
            ? {
                ...old,
                data: {
                  ...old.data,
                  preferences,
                },
              }
            : undefined,
      );
      return queryClient.invalidateQueries({
        queryKey: [getUserPreferencesReq.name],
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useNameAndEmail = () => {
  const email = useUserStore((state) => state.user)?.email;
  const { data: queryData } = useUserProfileQuery();
  const profileData = queryData?.data;

  const fullName =
    `${profileData?.first_name ?? ''} ${profileData?.last_name ?? ''}`.trim();
  const letters = useMemo(() => {
    if (fullName) return fullName.substring(0, 2).toUpperCase();
    if (email) return email.substring(0, 2).toUpperCase();
    return 'LI';
  }, [email, fullName]);
  return {
    email,
    fullName: fullName.length > 0 ? fullName : undefined,
    firstName: profileData?.first_name,
    lastName: profileData?.last_name,
    letters,
  };
};
