import EmailIcon from '@/assets/icons/email.svg?react';
import type { AuthForm } from '@/hooks/login-logout.hook';
import { FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Input } from '../ui/input';

export function AuthFormEmailInput(props: { form: AuthForm }) {
  return (
    <FormField
      control={props.form.control}
      name="email"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Input
              wrapperClassName="w-full"
              className="placeholder:text-secondary-foreground"
              placeholder="Email"
              icon={<EmailIcon className="text-secondary-foreground" />}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
