import { logger } from '@/lib/logger';
import { isUserLoggedIn } from '@/services/user-service';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  component: () => <Outlet />,
  beforeLoad: ({ location }) => {
    const token = isUserLoggedIn();
    if (!token) {
      logger.log('Not authorized, redirecting to login', location);

      // eslint-disable-next-line @typescript-eslint/only-throw-error -- This is a redirect
      throw redirect({
        to: location.pathname == '/back2school' ? '/continue' : '/login',
        search: {
          redirect:
            location.pathname !== '/back2school'
              ? location.pathname
              : undefined,
        },
      });
    }
  },
});
