import type {
  AudioConversionDTO,
  AudioItemDTO,
  AudioItemMetaData,
  TagDTO,
} from '@/lib/audio-utils';
import { flatChildren, useAudioTimeSec } from '@/lib/audio-utils';
import { logger } from '@/lib/logger';
import { useAudioStore } from '@/stores/audio-store';
import { useFeatureFlag } from '@/stores/feature-flag-store';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import {
  convertUrlAudioReq,
  deleteAudioItemReq,
  getChapterDetailsReq,
  getProcessingQueueReq,
  listAudioItemsReq,
  safeApiClient,
  updateAudioItemReq,
} from './api-service';
import { useUserToken, validTokenGuard } from './user-service';

export const sessionConversionHistoryAtom = atomWithStorage<
  (AudioConversionDTO | AudioItemMetaData)[]
>('sessionConversionHistoryAtom', []);

export const useListAudioQuery = () => {
  const { token, isAuthorized } = useUserToken();
  return useQuery({
    enabled: isAuthorized,
    queryKey: [listAudioItemsReq.name, token],
    queryFn: () => listAudioItemsReq(validTokenGuard(token)),
    refetchInterval: 25000,
  });
};

export const useProcessingQueueQuery = () => {
  const { token, isAuthorized } = useUserToken();
  return useQuery({
    enabled: isAuthorized,
    queryKey: [getProcessingQueueReq.name, token],
    queryFn: () => getProcessingQueueReq(validTokenGuard(token)),
    refetchInterval: 25000,
  });
};

export const useFetchAllChapterDetails = ({
  audioItem,
}: {
  audioItem: AudioItemDTO;
}) => {
  const flatChildren = useFlatChapters(audioItem);
  const { token } = useUserToken();

  return useQueries({
    queries: flatChildren.map((chapter) => ({
      gcTime: Infinity,
      staleTime: Infinity,
      queryKey: [
        getChapterDetailsReq.name,
        token,
        audioItem.audioConversionID,
        chapter.chapter_id,
      ],
      queryFn: () =>
        getChapterDetailsReq(
          validTokenGuard(token),
          audioItem.audioConversionID,
          chapter.chapter_id,
        ),
    })),
  });
};

export const useChapterDetailsQuery = ({
  audioConversionId,
  chapterId,
}: {
  audioConversionId?: string;
  chapterId?: string;
}) => {
  const { token } = useUserToken();

  return useQuery({
    queryKey: [getChapterDetailsReq.name, token, audioConversionId, chapterId],
    enabled: !!audioConversionId && !!chapterId,
    queryFn: () => {
      if (!audioConversionId || !chapterId)
        throw new Error('audioConversionId and chapterId must be provided');
      return getChapterDetailsReq(
        validTokenGuard(token),
        audioConversionId,
        chapterId,
      );
    },
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export const useCurrentAudioChapterDetailsQuery = () => {
  const chapter = useAudioStore((state) => state.chapter);
  const audioItem = useAudioStore((state) => state.audioItem);

  const audioConversionId = audioItem?.audioConversionID;
  const chapterId = chapter?.chapter_id;

  return {
    getChapterDetailsQuery: useChapterDetailsQuery({
      audioConversionId,
      chapterId,
    }),
    chapter,
    audioItem,
  };
};

export const isTimestampActive = (
  currentTime: number,
  ts: { start_ms: number; end_ms: number },
) => {
  const currentMs = currentTime * 1000;
  return currentMs >= ts.start_ms && currentMs < ts.end_ms;
};

export const useIsLabelActive = () => {
  const chapter = useAudioStore((state) => state.chapter);

  return useCallback(
    (
      currentTime: number,
      chapterId: string,
      ts: { start_ms: number; end_ms: number },
    ) => {
      return (
        chapter &&
        chapter.chapter_id === chapterId &&
        isTimestampActive(currentTime, ts)
      );
    },
    [chapter],
  );
};

export const useFlatChapters = (audioItem: AudioItemDTO) =>
  useMemo(
    () => flatChildren(audioItem.audioConversion.chapters),
    [audioItem.audioConversion.chapters],
  );

export const useFlatChaptersEmpty = (audioItem?: AudioItemDTO | null) =>
  useMemo(
    () => (audioItem ? flatChildren(audioItem.audioConversion.chapters) : []),
    [audioItem],
  );

export const useActiveText = (level: 'sentence' | 'paragraph') => {
  const { getChapterDetailsQuery } = useCurrentAudioChapterDetailsQuery();
  const time = useAudioTimeSec();

  if (!getChapterDetailsQuery.data) return null;

  const activeParagraph =
    getChapterDetailsQuery.data.timestamps.paragraph_timestamps.find((p) =>
      isTimestampActive(time, p),
    );
  if (level === 'sentence') {
    const activeSentence = activeParagraph?.sentence_timestamps.find((s) =>
      isTimestampActive(time, s),
    );
    const activeSentenceText =
      activeSentence?.word_timestamps.map((w) => w.text).join(' ') ?? null;
    return activeSentenceText;
  }
  const activeParagraphText =
    activeParagraph?.sentence_timestamps
      .map((s) => s.word_timestamps.map((w) => w.text).join(' '))
      .join(' ') ?? null;
  return activeParagraphText;
};

export const useCreateAudioConversion = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);
  const [v2ApiFeatureFlag] = useFeatureFlag('USE_V2_POST_API');

  return useMutation({
    mutationFn: async (url: string) => {
      if (!v2ApiFeatureFlag)
        return convertUrlAudioReq(url, validTokenGuard(token));
      const response = await safeApiClient.POST('/v2/audio', {
        body: {
          document_url: url,
        },
      });
      if (!response.data?.audio_conversion)
        throw new Error('No audio conversion data');
      return response.data.audio_conversion;
    },
    onSuccess: async (audioConversion, url) => {
      logger.log(`Successfully requested converted url`, url);
      setHistory((history) => [...history, audioConversion]);
      await queryClient.refetchQueries({
        queryKey: [getProcessingQueueReq.name],
      });
    },
    onError: (error) => {
      logger.error(error);
    },
    networkMode: 'always',
  });
};

export const useDeleteAudioMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (audioItemId: string) =>
      deleteAudioItemReq(validTokenGuard(token), audioItemId),
    onSuccess: (_, audioItemId) => {
      logger.log('Deleted audio item successfully');
      queryClient.setQueriesData(
        { queryKey: [listAudioItemsReq.name] },
        (old: { data: AudioItemDTO[] } | undefined) =>
          old != undefined
            ? {
                ...old,
                data: old.data.filter(
                  (item) => item.audioConversionID != audioItemId,
                ),
              }
            : undefined,
      );
      return queryClient.invalidateQueries({
        queryKey: [listAudioItemsReq.name],
      });
    },
  });
};

export const useUpdateAudioMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      audioId,
      newTags,
      newTitle,
    }: {
      audioId: string;
      newTags?: TagDTO[];
      newTitle?: string;
    }) =>
      updateAudioItemReq(validTokenGuard(token), audioId, newTags, newTitle),
    onSuccess: (_, { audioId, newTags, newTitle }) => {
      logger.log('Updated audio item successfully');

      queryClient.setQueriesData(
        { queryKey: [listAudioItemsReq.name] },
        (old: { data: AudioItemDTO[] } | undefined) =>
          old != undefined
            ? {
                ...old,
                data: old.data.map((item) =>
                  item.audioConversionID == audioId
                    ? {
                        ...item,
                        tags: newTags ? [...newTags] : item.tags,
                        title: newTitle ?? item.title,
                      }
                    : item,
                ),
              }
            : undefined,
      );
      return queryClient.invalidateQueries({
        queryKey: [listAudioItemsReq.name],
      });
    },
  });
};
