/* eslint-disable @eslint-react/no-children-map -- This was hard. See linked issues */
/* eslint-disable @eslint-react/no-clone-element -- This was hard. See linked issues */
// https://ui.shadcn.com/docs/components/dialog
// https://github.com/radix-ui/primitives/issues/1836
// https://codesandbox.io/embed/r9sq1q

import type * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { Dialog, DialogTrigger } from './dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu';

const DialogDropdown: React.FC<
  DropdownMenuPrimitive.DropdownMenuProps & {
    trigger: React.ReactElement;
    dropdownState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    menuContentRef?: React.RefObject<HTMLDivElement>;
    dropdownTriggerExtRef?: React.RefObject<HTMLDivElement>;
    dropdownOpenChanged?: (open: boolean) => void;
  }
> = ({
  children,
  trigger,
  dropdownState,
  menuContentRef,
  dropdownTriggerExtRef,
  dropdownOpenChanged,
  ...props
}) => {
  const internalDropdownState = React.useState(false);
  const [dropdownOpen, setDropdownOpenState] =
    dropdownState ?? internalDropdownState;

  const setDropdownOpen = React.useCallback(
    (val: boolean) => {
      dropdownOpenChanged?.(val);
      setDropdownOpenState(val);
    },
    [dropdownOpenChanged, setDropdownOpenState],
  );

  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);

  const dropdownTriggerRefInternal = React.useRef<HTMLDivElement | null>(null);
  const dropdownTriggerRef =
    dropdownTriggerExtRef ?? dropdownTriggerRefInternal;
  const focusRef = React.useRef<HTMLDivElement | null>(null);

  const handleDialogItemSelect = React.useCallback(() => {
    focusRef.current = dropdownTriggerRef.current;
  }, [dropdownTriggerRef, focusRef]);

  const handleDialogItemOpenChange = React.useCallback(
    (open: boolean) => {
      setHasOpenDialog(open);
      if (!open) {
        setDropdownOpen(false);
      }
    },
    [setHasOpenDialog, setDropdownOpen],
  );

  const traverseChildren = React.useCallback(
    (children: React.ReactNode): React.ReactNode => {
      return React.Children.map(children, (child) => {
        if (
          React.isValidElement<React.ComponentProps<typeof DialogItem>>(child)
        ) {
          if (child.type === DialogItem) {
            return React.cloneElement(child, {
              parentOnSelect: handleDialogItemSelect,
              parentOnOpenChange: handleDialogItemOpenChange,
            });
          } else if (child.type === React.Fragment) {
            return <>{traverseChildren(child.props.children)}</>;
          }
        }
        return child;
      });
    },
    [handleDialogItemSelect, handleDialogItemOpenChange],
  );

  return (
    <DropdownMenu
      {...props}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        {React.cloneElement(trigger, {
          ref: dropdownTriggerRef,
        })}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        hidden={hasOpenDialog}
        ref={menuContentRef}
        onCloseAutoFocus={(e) => {
          if (focusRef.current) {
            focusRef.current.focus();
            focusRef.current = null;
            e.preventDefault();
          }
        }}
      >
        {traverseChildren(children)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
DialogDropdown.displayName = 'DialogDropdown';

export const DialogCloseContext = React.createContext<{
  closeDialog: () => void;
  open?: boolean;
}>({ closeDialog: () => null });

/**
 * Provides a context for managing the state of a dialog close event.
 * This is an ugly hack. Radix UI does not provide a way to close a dialog from a child component
 * without making the Dialog a controlled component. This is a workaround to allow a child component
 * to close a dialog without making the Dialog a controlled component.
 *
 * @param children - The content to be rendered inside the DialogCloseProvider.
 * @returns The DialogCloseProvider component.
 */
export function DialogCloseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <DialogCloseContext.Provider value={{ closeDialog, open }}>
      <Dialog
        open={open}
        onOpenChange={(val) => {
          setOpen(val);
        }}
      >
        {children}
      </Dialog>
    </DialogCloseContext.Provider>
  );
}

const DialogItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  } & {
    trigger?: React.ReactNode;
    parentOnSelect?: () => void;
    parentOnOpenChange?: (open: boolean) => void;
    dialogOpenState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    onDialogOpenChanged?: (open: boolean) => void;
  }
>((props, ref) => {
  const {
    trigger,
    children,
    parentOnSelect,
    parentOnOpenChange,
    dialogOpenState,
    onDialogOpenChanged,
    ...itemProps
  } = props;

  const internalOpenState = React.useState(false);
  const [open, setOpen] = dialogOpenState ?? internalOpenState;

  const close = React.useCallback(() => {
    setOpen(false);
    parentOnOpenChange?.(false);
  }, [setOpen, parentOnOpenChange]);

  return (
    <DialogCloseContext.Provider value={{ closeDialog: close }}>
      <Dialog
        onOpenChange={(val) => {
          onDialogOpenChanged?.(val);
          parentOnOpenChange?.(val);
          setOpen(val);
        }}
        open={open}
      >
        <DialogTrigger asChild>
          <DropdownMenuItem
            {...itemProps}
            ref={ref}
            onSelect={(event) => {
              event.preventDefault();
              parentOnSelect?.();
            }}
          >
            {trigger}
          </DropdownMenuItem>
        </DialogTrigger>
        {children}
      </Dialog>
    </DialogCloseContext.Provider>
  );
});
DialogItem.displayName = 'DialogItem';

export { DialogDropdown, DialogItem };
