type ApiExceptionType =
  | 'INVALID_URL'
  | 'INVALID_JSON'
  | 'TIMEOUT'
  | 'FETCH_ERROR' //No response
  | 'CLIENT_ERROR'
  | 'SERVER_ERROR'
  | 'TOO_MANY_REQUESTS';

export class ApiException extends Error {
  constructor(
    public message: string,
    public type: ApiExceptionType,
    public response?: Response,
    public cause?: unknown,
    public backendErrorPayload?: {
      error_reason: string;
      error_type: string;
    },
  ) {
    super(
      `API Exception ${type} ${message} ${backendErrorPayload?.toString() ?? ''}`,
      {
        cause,
      },
    );
  }

  static getErrorMessage(
    e: unknown,
    defaultMessage = 'Unknown error occurred',
  ) {
    const error = ApiException.fromError(e);
    if (!error) return defaultMessage;
    if (error.backendErrorPayload)
      return error.backendErrorPayload.error_reason;

    if (error.type == 'TOO_MANY_REQUESTS')
      return 'Too many requests. Please try again later.';

    return error.message;
  }

  static fromError(e: unknown): ApiException | undefined {
    if (e instanceof ApiException) {
      return e;
    }
    return undefined;
  }
}
