import { useLastPlayedAudioStore } from '@/components/misc/providers/audio-load-provider';
import { hideExtensionTipAtom } from '@/components/web-extension/hide-tip-atom';
import { ApiException } from '@/lib/exceptions';
import { auth, useFirebaseSignOut } from '@/lib/firebase';
import { logger } from '@/lib/logger';
import { useGlobalAudioPlayer } from '@/lib/react-use-audio-player/useGlobalAudioPlayer';
import { getUtmParams } from '@/lib/utm';
import { trackLogLoginSuccess } from '@/services/analytics-service';
import { sessionConversionHistoryAtom } from '@/services/audio-service';
import { useLoginMutation, useRegisterMutation } from '@/services/auth-service';
import { useCheckoutAttemptStore } from '@/services/subscription-service';
import { useUserStore } from '@/services/user-service';
import { useAudioStore, useLocalPrefsStore } from '@/stores/audio-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import { signInWithPopup, type AuthProvider } from 'firebase/auth';
import { useAtom } from 'jotai';
import { useState } from 'react';
import type { Resolver } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const authFormSchema = z.object({
  email: z.string().email().min(1).max(80),
  password: z.string().min(1).max(80),
});

type FormValues = z.infer<typeof authFormSchema>;
type AuthMode = 'login' | 'signup';
export function useAuthAndNavigate(opts?: {
  redirect?: string | false;
  initialMode?: AuthMode;
  registerAllowed?: boolean;
}) {
  const form = useAuthForm();
  const [mode, setMode] = useState<AuthMode>(opts?.initialMode ?? 'login');
  const navigate = useNavigate({ from: '/login' });
  const search = useSearch({
    strict: false,
  });
  const redirectTo =
    opts?.redirect == false
      ? undefined
      : (opts?.redirect ??
        ('redirect' in search ? search.redirect : undefined));
  const router = useRouter();
  const loginMutation = useLoginMutation();
  const registerMutation = useRegisterMutation();
  const firebaseSignOutMutation = useFirebaseSignOut();

  const mutation = useMutation({
    mutationFn: async (values: Parameters<typeof onSubmit>[0]) => {
      const data = await (async () => {
        if ('oauthProvider' in values) {
          const userCred = await signInWithPopup(auth, values.oauthProvider);
          logger.log('Firebase user', userCred.user.uid);
          const token = await userCred.user.getIdToken();
          const email = userCred.user.email;
          if (!email) throw new Error('Email not found');
          return {
            firebase_id_token: token,
            email,
          };
        }
        return values;
      })();

      const defaultRegisterProps = {
        email: data.email,
        utmParams: getUtmParams(),
      };

      if ('firebase_id_token' in data) {
        // first try to login
        try {
          return await loginMutation.mutateAsync(data, {
            onSuccess: handleSuccess,
          });
        } catch (e) {
          const userNotFound =
            ApiException.fromError(e)?.response?.status === 404;
          const oauthProvider =
            'oauthProvider' in values ? values.oauthProvider : null;
          const providerLabel =
            oauthProvider?.providerId === 'google.com'
              ? 'Google'
              : oauthProvider?.providerId === 'apple.com'
                ? 'Apple'
                : 'Unknown';
          const betterUserNotFoundError = new ApiException(
            `You’ve signed up using email and password. To log in with ${providerLabel}, log in with email first, then add your ${providerLabel} account in the Settings page`,
            'CLIENT_ERROR',
          );
          if (opts?.registerAllowed != true && userNotFound) {
            throw betterUserNotFoundError;
          }
          if (userNotFound) {
            // if login fails, try to register
            const result = await registerMutation.mutateAsync(
              {
                firebase_id_token: data.firebase_id_token,
                random_password: true,
                ...defaultRegisterProps,
              },
              {
                onSuccess: handleSuccess,
                onError: (e) => {
                  let error = e;
                  if (
                    ApiException.fromError(e)?.message ==
                    'A user with this email already exists please sign in or reset your password'
                  ) {
                    error = betterUserNotFoundError;
                  }
                  handleError(error, values);
                },
              },
            );
            return result;
          } else {
            throw e;
          }
        }
      }

      if (mode === 'signup' && opts?.registerAllowed == true) {
        return await registerMutation.mutateAsync({
          password: data.password,
          random_password: false,
          ...defaultRegisterProps,
        });
      }

      return await loginMutation.mutateAsync(data);
    },
  });

  const handleError = (e: unknown, values: Parameters<typeof onSubmit>[0]) => {
    logger.log('handleError');
    const apiError = ApiException.fromError(e);
    logger.warn(`${mode} request failed`);
    if (!apiError) {
      form.setError('root', {
        type: '400',
        message: ApiException.getErrorMessage(e),
      });
      return;
    }
    if ('oauthProvider' in values && apiError.response?.status === 404) {
      firebaseSignOutMutation.mutate();
      form.setError('root', {
        type: '400',
        message: 'User not found',
      });
      throw e;
    }
    form.setError('root', {
      type: '400',
      message: ApiException.getErrorMessage(e),
    });
  };
  const handleSuccess = () => {
    logger.log('handleSuccess');
    trackLogLoginSuccess();
    logger.log(`${mode} successful, navigating to ${redirectTo ?? '/'}`);
    if (redirectTo) {
      router.history.push(redirectTo);
    } else {
      void navigate({ to: '/' });
    }
  };

  function onSubmit(
    values: z.infer<typeof authFormSchema> | { oauthProvider: AuthProvider },
  ) {
    form.clearErrors('root');
    form.clearErrors('email');
    form.clearErrors('password');
    mutation.mutate(values, {
      onError: (e) => {
        handleError(e, values);
      },
      onSuccess: handleSuccess,
    });
  }

  return { onSubmit, mutation, form, mode, setMode };
}

export type AuthForm = ReturnType<typeof useAuthForm>;
function useAuthForm() {
  const form = useForm<FormValues>({
    resolver: zodResolver(authFormSchema) as Resolver<FormValues>,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return form;
}

export function useLogoutAndClearCache() {
  const logout = useUserStore((state) => state.logout);
  const audioPlayer = useGlobalAudioPlayer();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);
  const [, setHideTip] = useAtom(hideExtensionTipAtom);
  const queryClient = useQueryClient();
  const setAudioItem = useAudioStore((state) => state.setAudioItem);
  const localPrefsStoreReset = useLocalPrefsStore((s) => s.reset);
  const signOutFirebase = useFirebaseSignOut();
  const checkoutAttemptStore = useCheckoutAttemptStore();

  return () => {
    useLastPlayedAudioStore.getState().reset();
    checkoutAttemptStore.setLastCheckoutAttempt(undefined);
    audioPlayer.stop();
    logout();
    setHistory([]);
    setHideTip(false);
    setAudioItem(null);
    queryClient.clear();
    void queryClient.resetQueries();
    signOutFirebase.mutate();
    localPrefsStoreReset();
  };
}
