/// <reference types="vite-plugin-svgr/client" />
// import DiscoveryIcon from '@/assets/icons/discovery.svg?react';
// import FilesIcon from '@/assets/icons/files.svg?react';
// import HeartIcon from '@/assets/icons/heart.svg?react';
// import TimerIcon from '@/assets/icons/timer.svg?react';
import CloseNavIcon from '@/assets/icons/closeNav.svg?react';
import HomeIcon from '@/assets/icons/home.svg?react';
import LinkIcon from '@/assets/icons/link.svg?react';
import PageIcon from '@/assets/icons/page.svg?react';
import ReferIcon from '@/assets/icons/refer.svg?react';
import StickyNoteIcon from '@/assets/icons/stickyNote.svg?react';
import logoBig from '@/assets/imgs/logo/listeningLogoLong.svg';
import logoBigDark from '@/assets/imgs/logo/listeningLogoLongDark.svg';
import logoSmall from '@/assets/imgs/logo/logoSmall.svg';
import { cn, stringToNumberHash, useContextAndErrorIfNull } from '@/lib/utils';

import {
  useLastPlayedAudioItems,
  useRecentAudioItems,
} from '@/hooks/audio-items.hooks';
import { type AudioItemDTO } from '@/lib/audio-utils';
import { useUserToken } from '@/services/user-service';
import { Link, useMatchRoute, type LinkProps } from '@tanstack/react-router';
import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  type ComponentProps,
  type ComponentType,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { BlurThumbnail } from './audio/blur-thumbnail';
import { ForwardEmailDialog } from './dialogs/forward-email-dialog';
import { LinkWebDialog } from './dialogs/link-web-dialog';
import { UploadFileDialog } from './dialogs/upload-file-dialog';
import { useLoadAudioAndNavigate } from './misc/providers/audio-load-provider';
import { useTheme } from './misc/providers/theme-provider';
import { Button } from './ui/button';
import { Dialog, DialogTrigger } from './ui/dialog';
import { ScrollArea } from './ui/scroll-area';
import { Separator } from './ui/separator';
import { TooltipButton } from './ui/tooltip-button';

const useIsCollapsed = () => {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const [collapsed, setCollapsed] = useState(isSmallScreen);

  useEffect(() => {
    setCollapsed(isSmallScreen);
  }, [isSmallScreen]);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, [setCollapsed]);

  return [collapsed, toggleCollapsed] as const;
};

const CollapsedContext = createContext<ReturnType<
  typeof useIsCollapsed
> | null>(null);

export function NavBar() {
  const [collapsed, toggleCollapsed] = useIsCollapsed();

  const { isAuthorized } = useUserToken();

  if (!isAuthorized) return <></>;

  return (
    <CollapsedContext.Provider value={[collapsed, toggleCollapsed]}>
      <div
        className={cn(
          `row-span-2 shrink-0 p-4 transition-all duration-500 ${
            collapsed ? 'w-[112px]' : 'w-[260px]'
          }`,
        )}
      >
        <nav
          className={cn(
            'group relative flex h-full flex-col items-center gap-4 overflow-hidden rounded-2xl bg-white shadow-[0px_2px_14px_0px_rgba(142,174,200,0.20)] dark:bg-[#171A24] dark:shadow-[0px_2px_14px_0px_rgba(142,174,200,0.05)]',
            collapsed && 'gap-1',
          )}
        >
          <LogoLink />
          <ScrollArea className="w-full" useFlexFix>
            <div
              className={cn(
                'flex flex-col items-center gap-4 pb-8',
                collapsed && 'gap-2',
              )}
            >
              <MenuSection />
              <ContinueListeningSection />
              <UploadFileSection />
              <RecentSection />
            </div>
          </ScrollArea>
          <div className="pointer-events-none absolute inset-x-0 bottom-0 h-[52px] w-full bg-gradient-to-t from-white to-transparent dark:from-[#131829] dark:to-transparent" />
        </nav>
      </div>
    </CollapsedContext.Provider>
  );
}

function LogoLink() {
  const [collapsed, toggleCollapsed] =
    useContextAndErrorIfNull(CollapsedContext);
  const { isLight } = useTheme();
  return (
    <div
      className={cn(
        'flex w-full items-center justify-between overflow-x-clip px-2 pt-6',
        collapsed && 'flex-col justify-center',
      )}
    >
      <Link
        to="/"
        className={cn('pl-4', collapsed && 'flex justify-center px-0')}
      >
        {collapsed ? (
          <div className="flex size-8 items-center justify-center">
            <img
              src={logoSmall}
              alt="Listening logo"
              className="object-scale-down"
            />
          </div>
        ) : (
          <img
            src={isLight ? logoBig : logoBigDark}
            alt="Listening logo"
            className="w-[126px] object-scale-down"
          />
        )}
      </Link>
      <Button
        variant="ghost"
        onClick={toggleCollapsed}
        className={cn(
          'p-2 opacity-0 transition-all group-hover:opacity-100',
          collapsed && 'rotate-180 opacity-100',
        )}
      >
        <CloseNavIcon className="text-[#667085] dark:text-[#B8C4D6]" />
      </Button>
    </div>
  );
}

function NavBarSection({
  label,
  children,
}: {
  label: string;
  children?: React.ReactNode;
}) {
  const [collapsed] = useContextAndErrorIfNull(CollapsedContext);

  return (
    <>
      {collapsed && <Separator className="w-8 self-center" />}
      <div
        className={cn('flex w-full flex-col items-start', collapsed && 'py-1')}
      >
        {!collapsed && (
          <p className="w-full truncate px-6 pb-0.5 text-[10px] font-medium uppercase leading-[2.4] text-[#667084]">
            {label}
          </p>
        )}
        {children}
      </div>
    </>
  );
}

function MenuSection() {
  const navLinks: ComponentProps<typeof NavItem>[] = [
    {
      Icon: HomeIcon,
      label: 'Homepage',
      linkTo: '/home',
    },
    // {
    //   Icon: DiscoveryIcon,
    //   label: 'Discovery',
    //   linkTo: '/',
    // },
    // {
    //   Icon: FilesIcon,
    //   label: 'My Files',
    //   linkTo: '/',
    // },
    {
      Icon: StickyNoteIcon,
      label: 'Highlights',
      linkTo: '/notes',
    },
    // {
    //   Icon: TimerIcon,
    //   label: 'Listen Later',
    //   linkTo: '/',
    // },
    // {
    //   Icon: HeartIcon,
    //   label: 'Favorites',
    //   linkTo: '/',
    // },
  ];

  return (
    <NavBarSection label="Menu">
      {navLinks.map((navLink) => (
        <NavItem key={navLink.label} {...navLink} />
      ))}
    </NavBarSection>
  );
}

function NavItem({
  Icon,
  label,
  linkTo,
}: {
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  label: string;
  linkTo: LinkProps['to'];
}) {
  const [collapsed] = useContextAndErrorIfNull(CollapsedContext);

  const matchRoute = useMatchRoute();

  const isActive = !!matchRoute({
    to: linkTo,
    fuzzy: true,
  }) as boolean;

  return (
    <TooltipButton
      toolTipSide="right"
      toolTipEnabled={collapsed}
      toolTipContent={label}
      variant="ghost"
      className={cn(
        'flex h-auto w-full gap-4 rounded-none',
        collapsed
          ? 'justify-center px-3 py-[10px]'
          : 'justify-start px-6 py-[8px]',
      )}
      asChild
    >
      <Link to={linkTo}>
        <Icon
          className={cn(
            'size-5 shrink-0 transition-all',
            isActive ? 'text-[#0587FB]' : 'text-[#667085] dark:text-[#B8C4D6]',
          )}
        />
        {!collapsed && (
          <p
            className={cn(
              'truncate text-xs font-medium leading-loose',
              isActive ? 'text-[#080124] dark:text-white' : 'text-[#667084]',
            )}
          >
            {label}
          </p>
        )}
      </Link>
    </TooltipButton>
  );
}

function ContinueListeningSection() {
  const lastPlayedAudios = useLastPlayedAudioItems();
  if (lastPlayedAudios.length === 0) return null;

  return (
    <NavBarSection label="Continue Listening">
      {lastPlayedAudios
        .slice(0, Math.min(lastPlayedAudios.length, 5))
        .map((audio) => (
          <NavAudioItem
            key={audio.audioItem.audioConversionID}
            audioItem={audio.audioItem}
            subHeading={audio.chapter?.title}
          />
        ))}
    </NavBarSection>
  );
}

function UploadFileSection() {
  const [collapsed] = useContextAndErrorIfNull(CollapsedContext);

  const items: {
    Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    label: string;
    DialogContent: React.ReactNode;
  }[] = [
    {
      Icon: PageIcon,
      label: 'Document',
      DialogContent: <UploadFileDialog />,
    },
    {
      Icon: LinkIcon,
      label: 'Web page',
      DialogContent: <LinkWebDialog />,
    },
    {
      Icon: ReferIcon,
      label: 'Forward an Email',
      DialogContent: <ForwardEmailDialog />,
    },
  ];

  return (
    <NavBarSection label="Upload Files">
      {items.map((item) => (
        <Dialog key={item.label}>
          <DialogTrigger asChild>
            <TooltipButton
              toolTipSide="right"
              toolTipEnabled={collapsed}
              toolTipContent={`Upload ${item.label}`}
              variant="ghost"
              className={cn(
                'flex h-auto w-full gap-4 rounded-none',
                collapsed
                  ? 'justify-center px-3 py-[10px]'
                  : 'justify-start px-6 py-2',
              )}
            >
              <item.Icon
                className={'size-5 shrink-0 text-[#667085] dark:text-[#B8C4D6]'}
              />

              {!collapsed && (
                <p className="truncate text-xs font-medium leading-normal text-[#667084] dark:text-[#B8C4D6]">
                  {item.label}
                </p>
              )}
            </TooltipButton>
          </DialogTrigger>
          {item.DialogContent}
        </Dialog>
      ))}
    </NavBarSection>
  );
}

function NavAudioItem({
  audioItem,
  subHeading,
}: {
  audioItem: AudioItemDTO;
  subHeading?: string;
}) {
  const [collapsed] = useContextAndErrorIfNull(CollapsedContext);
  const { isDark } = useTheme();
  const loadAudioAndNav = useLoadAudioAndNavigate();

  return (
    <TooltipButton
      onClick={() => {
        loadAudioAndNav(audioItem);
      }}
      toolTipSide="right"
      toolTipEnabled={collapsed}
      toolTipContent={
        <div className="flex flex-col">
          <p className="text-center text-xs font-semibold text-[#344054] dark:text-[#B8C4D6]">
            {audioItem.title}
          </p>
          <p className="text-center text-xs text-[#344054] dark:text-[#B8C4D6]">
            {subHeading}
          </p>
        </div>
      }
      variant="ghost"
      className={cn(
        'flex h-auto w-full gap-3 overflow-hidden rounded-none py-1',
        collapsed ? 'px-3' : 'pl-6',
      )}
      key={audioItem.audioConversionID}
    >
      <BlurThumbnail
        preset={stringToNumberHash(audioItem.title ?? '1')}
        className={cn(collapsed && 'size-8', isDark && 'opacity-75')}
      />
      {!collapsed && (
        <div className="flex w-full flex-col items-start justify-start gap-[2px] overflow-hidden">
          <p className="w-full truncate text-start text-xs font-medium leading-[1.35] text-[#080023] dark:text-white">
            {audioItem.title}
          </p>
          <p className="w-full truncate text-start text-[10px] font-medium leading-normal text-[#667084] dark:text-[#B8C4D6]">
            {subHeading}
          </p>
        </div>
      )}
    </TooltipButton>
  );
}

function RecentSection() {
  const items = useRecentAudioItems();

  return (
    <NavBarSection label="Recent">
      {items.slice(0, 5).map((audioItem) => (
        <NavAudioItem key={audioItem.audioConversionID} audioItem={audioItem} />
      ))}
    </NavBarSection>
  );
}
