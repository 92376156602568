import PasswordIcon from '@/assets/icons/password.svg?react';
import type { AuthForm } from '@/hooks/login-logout.hook';
import { FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Input } from '../ui/input';

export function AuthFormPasswordInput(props: { form: AuthForm }) {
  return (
    <FormField
      control={props.form.control}
      name="password"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Input
              wrapperClassName="w-full mt-4"
              className="placeholder:text-secondary-foreground"
              placeholder="Password"
              type="password"
              autoComplete="current-password"
              icon={<PasswordIcon className="text-secondary-foreground" />}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
