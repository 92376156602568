/* eslint-disable react-refresh/only-export-components -- I don't need fast refresh */
import type { AudioItemDTO, ChapterDTO } from '@/lib/audio-utils';
import {
  DEFAULT_TITLE,
  flatChildren,
  getAudioUrl,
  getFirstChapter,
} from '@/lib/audio-utils';
import { logger } from '@/lib/logger';
import type { AudioLoadOptions } from '@/lib/react-use-audio-player/types';
import { useGlobalAudioPlayer } from '@/lib/react-use-audio-player/useGlobalAudioPlayer';
import { clamp, useContextAndErrorIfNull, waitForElement } from '@/lib/utils';
import {
  trackLogStartedPlayingAudio,
  trackLogStoppedPlayingAudio,
} from '@/services/analytics-service';
import { useAudioStore, useLocalPrefsStore } from '@/stores/audio-store';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

function useLoadAudioItemInternal() {
  const { load, looping, seek } = useGlobalAudioPlayer();
  const { endSession } = useTrackPlayTime();
  const playbackRate = useLocalPrefsStore((state) => state.playbackRate);
  const volume = useLocalPrefsStore((state) => state.volume);
  const setAudioItem = useAudioStore((state) => state.setAudioItem);
  const currentAudioItem = useAudioStore((state) => state.audioItem);
  const currentChapter = useAudioStore((state) => state.chapter);

  return {
    loadAudioItem: (
      audioItem: AudioItemDTO,
      chapter?: ChapterDTO,
      startingPosMs?: number,
      options?: AudioLoadOptions,
    ) => {
      endSession();
      const { selectedChapter, pos } = useLastPlayedAudioStore
        .getState()
        .getLastPlayedPositionMs(audioItem, chapter);

      const selectedPos = startingPosMs ?? pos;
      if (selectedChapter === currentChapter && audioItem === currentAudioItem)
        return selectedChapter;

      try {
        const firstSrc = getAudioUrl({ audioItem, chapter });

        load(firstSrc, {
          autoplay: true,
          ...options,
          html5: true,
          initialRate: clamp(playbackRate, 0.01, 16),
          initialVolume: clamp(volume, 0, 1),
          onload: () => {
            useLastPlayedAudioStore
              .getState()
              .setLastPlayedPositionMs(
                audioItem.audioConversionID,
                selectedChapter?.chapter_id ?? 'default',
                selectedPos,
              );
            if (selectedPos) {
              logger.log('Seeking to', selectedPos / 1000);
              // without the setTimeout, the seek doesn't work
              setTimeout(() => {
                seek(selectedPos / 1000);
                if (startingPosMs) return;
                void waitForElement(
                  '[data-jump-to-current-btn="true"]',
                  6 * 1000,
                ).then((el) => {
                  if (el) {
                    logger.log('Clicking jump to current button');
                    (el as HTMLElement).click();
                  } else {
                    logger.warn('Jump to current button not found');
                  }
                });
              }, 250);
            }
          },
          onend: () => {
            logger.log('Audio ended');
            onend(load, looping);
          },
        });
        setAudioItem(audioItem, selectedChapter);

        return selectedChapter;
      } catch (error) {
        logger.error('Error while loading audio', error);
        setAudioItem(null, null);
        throw error;
      }
    },
  };
}

function onend(
  load: (src: string, options?: AudioLoadOptions) => void,
  looping: boolean,
) {
  if (looping) return;

  const audioItem = useAudioStore.getState().audioItem;
  if (audioItem === null) {
    logger.log('No audio item, not playing next chapter');
    return;
  }

  logger.log(`Finished playing ${audioItem.title ?? DEFAULT_TITLE}`);
  const chapter = useAudioStore.getState().chapter;
  if (chapter === null) {
    logger.log('No chapter selected, not playing next chapter');
    return;
  }
  const chapters = flatChildren(audioItem.audioConversion.chapters);
  const chapterIdx = chapters.findIndex(
    (c) => c.chapter_id === chapter.chapter_id,
  );

  const shuffle = useAudioStore.getState().shuffle;
  const nextChapterIdx = shuffle
    ? Math.floor(Math.random() * chapters.length)
    : chapterIdx + 1;

  const nextChapter = chapters[nextChapterIdx];

  if (!nextChapter) {
    logger.log('Last chapter, not playing next chapter');
    return;
  }
  useAudioStore.getState().setChapter(nextChapter);
  load(nextChapter.audio_url ?? nextChapter.audio_preview_url, {
    html5: true,
    autoplay: true,
    initialRate: useLocalPrefsStore.getState().playbackRate,
    initialVolume: useLocalPrefsStore.getState().volume,

    onend: () => {
      onend(load, looping);
    },
  });
}

type LoadFunction = ReturnType<
  typeof useLoadAudioItemInternal
>['loadAudioItem'];
const AudioLoadContext = React.createContext<{
  loadAudio: LoadFunction;
  // We know that all the access to this will be within the provider
} | null>(null);

export const AudioLoadProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { loadAudioItem } = useLoadAudioItemInternal();
  return (
    <AudioLoadContext.Provider value={{ loadAudio: loadAudioItem }}>
      {children}
    </AudioLoadContext.Provider>
  );
};

export function useLoadAudioAndNavigate() {
  const loadAudio = useLoadAudio();
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  return (audioItem: AudioItemDTO, chapter?: ChapterDTO) => {
    const selectedChapter = loadAudio(audioItem, chapter);
    const params = matchRoute({ to: '/readalong/$audioId/$chapterId' });
    if (
      params &&
      params.audioId === audioItem.audioConversionID &&
      params.chapterId === chapter?.chapter_id
    )
      return;

    void navigate({
      to: '/readalong/$audioId/$chapterId',
      params: {
        audioId: audioItem.audioConversionID,
        chapterId: selectedChapter?.chapter_id ?? '',
      },
    });
  };
}

export function useLoadAudio() {
  return useContextAndErrorIfNull(AudioLoadContext).loadAudio;
}

function useTrackPlayTime() {
  const { playing, rate, duration, getPosition } = useGlobalAudioPlayer();
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);

  const [sessionDetails, setSessionDetails] = useState<null | {
    audioItem: AudioItemDTO;
    chapter: ChapterDTO | null;

    sessionId: string;
    startTime: number;
    rate: number;
  }>(null);

  const startSession = useCallback(() => {
    if (!audioItem) {
      logger.error('No audio item found, cannot start play time session');
      return;
    }
    if (!playing) {
      logger.error('Audio is not playing, cannot start play time session');
      return;
    }
    const sessionDetails = {
      audioItem,
      chapter,
      sessionId: crypto.randomUUID(),
      startTime: Date.now(),
      rate,
    };

    setSessionDetails(sessionDetails);
    trackLogStartedPlayingAudio({
      audio_item_id: audioItem.audioConversionID,
      chapter_id: chapter?.chapter_id,
      chapter_title: chapter?.title,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: rate,
      audio_duration_sec: duration,
      starting_position_sec: getPosition(),
    });
  }, [audioItem, chapter, duration, getPosition, playing, rate]);

  const endSession = useCallback(() => {
    if (!sessionDetails) {
      logger.error('No session details found, cannot end play time session');
      return;
    }
    const endTime = Date.now();
    const duration = (endTime - sessionDetails.startTime) * sessionDetails.rate;
    const rateModifiedDuration = rate * duration;
    useLastPlayedAudioStore
      .getState()
      .setLastPlayedPositionMs(
        sessionDetails.audioItem.audioConversionID,
        sessionDetails.chapter?.chapter_id ?? '',
        getPosition() * 1000,
      );
    trackLogStoppedPlayingAudio({
      audio_item_id: sessionDetails.audioItem.audioConversionID,
      chapter_id: sessionDetails.chapter?.chapter_id,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: sessionDetails.rate,
      rate_modified_duration_sec: rateModifiedDuration / 1000,
      real_world_duration_sec: duration / 1000,
    });

    setSessionDetails(null);
  }, [getPosition, rate, sessionDetails]);

  const restartSession = useCallback(() => {
    endSession();
    startSession();
  }, [endSession, startSession]);

  useEffect(() => {
    if (playing && !sessionDetails) {
      startSession();
    } else if (!playing && sessionDetails) {
      endSession();
    } else if (sessionDetails && rate !== sessionDetails.rate) {
      restartSession();
    }
  }, [endSession, playing, rate, restartSession, sessionDetails, startSession]);

  return {
    endSession,
    restartSession,
    startSession,
  };
}

type LastPlayedAudioStoreState = {
  getLastPlayedPositionMs: (
    audioItem: AudioItemDTO,
    chapter?: ChapterDTO,
  ) => {
    selectedChapter: ChapterDTO | undefined;
    pos: number;
  };
  setLastPlayedPositionMs: (
    audioItemId: string,
    chapterId: string,
    position: number,
  ) => void;
  reset: () => void;

  _positions: Record<
    string,
    Record<
      string,
      {
        position: number;
        endSessionTime: number;
      }
    >
  >;
};

export const useLastPlayedAudioStore = create<LastPlayedAudioStoreState>()(
  persist(
    (set, get) => ({
      getLastPlayedPositionMs: (audioItem, chapter) => {
        const selectedChapter =
          chapter ?? getLastPlayedChapterOrFirst(audioItem);
        const pos = selectedChapter?.chapter_id
          ? (get()._positions[audioItem.audioConversionID]?.[
              selectedChapter.chapter_id
            ]?.position ?? 0)
          : 0;

        return { selectedChapter, pos };
      },
      setLastPlayedPositionMs: (audioItemId, chapterId, position) => {
        set((state) => {
          if (!state._positions[audioItemId])
            state._positions[audioItemId] = {};
          state._positions[audioItemId][chapterId] = {
            position,
            endSessionTime: new Date().getTime(),
          };

          return {
            ...state,
            _positions: { ...state._positions },
          };
        });
      },
      reset: () => {
        set({ _positions: {} });
      },
      _positions: {},
    }),
    {
      name: 'last-played-audio-store',
    },
  ),
);

export function lastPlayedAudiosFromStorePositions(
  _positions: LastPlayedAudioStoreState['_positions'],
) {
  const lastPlayed = Object.entries(_positions).map(
    ([audioId, chapterMetaData]) => {
      const chapterListMetaData = Object.entries(chapterMetaData).map(
        ([chapterId, data]) => ({
          chapterId,
          ...data,
        }),
      );
      const lastPlayedChapter = chapterListMetaData.sort(
        (a, b) => b.endSessionTime - a.endSessionTime,
      )[0];

      if (!lastPlayedChapter) {
        useLastPlayedAudioStore.getState().reset();
        return [];
      }

      return {
        audioId,
        chapterId: lastPlayedChapter.chapterId,
        position: lastPlayedChapter.position,
        endSessionTime: new Date(lastPlayedChapter.endSessionTime),
      };
    },
  );

  return lastPlayed
    .flat()
    .sort((b, a) => a.endSessionTime.getTime() - b.endSessionTime.getTime());
}

export const getLastPlayedChapterOrFirst = (audioItem: AudioItemDTO) => {
  const flatChapters = flatChildren(audioItem.audioConversion.chapters);
  const state: LastPlayedAudioStoreState = useLastPlayedAudioStore.getState();

  const chapterPlaySessions = state._positions[audioItem.audioConversionID];
  if (!chapterPlaySessions) return getFirstChapter(audioItem);

  const lastPlayedChapterId = Object.entries(chapterPlaySessions)
    .map(([chapterId, data]) => ({ chapterId, ...data }))
    .sort((a, b) => b.endSessionTime - a.endSessionTime)[0]?.chapterId;
  if (!lastPlayedChapterId) return getFirstChapter(audioItem);
  const lastPlayedChapter = flatChapters.find(
    (c) => c.chapter_id === lastPlayedChapterId,
  );
  return lastPlayedChapter ?? getFirstChapter(audioItem);
};
