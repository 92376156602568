import { PaymentIntentDialogContext } from '@/components/plan/payment-intent-dialog-provider';
import { logger } from '@/lib/logger';
import { delayMs, useContextAndErrorIfNull } from '@/lib/utils';
import { useUserStore } from '@/services/user-service';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { differenceInMinutes } from 'date-fns';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  cancelStripeSubscriptionReq,
  getPaymentDetailsReq,
  getSubscriptionsReq,
  getUserSummaryReq,
  modifyStripePaymentMethodReq,
  modifyStripeSubscriptionReq,
  postCancelSurveyReq,
  resubscribeStripeSubscriptionReq,
  successfulPaymentIntentReq,
} from './api-service';
import { useUserToken, validTokenGuard } from './user-service';

export const usePaymentSubscriptionsQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getSubscriptionsReq.name, token],
    queryFn: () => getSubscriptionsReq(validTokenGuard(token)),
    staleTime: 1000 * 60 * 60, // 1 hour
    retry: false,
  });
};
export const usePaymentDetailsQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getPaymentDetailsReq.name, token],
    queryFn: () => getPaymentDetailsReq(validTokenGuard(token)),
    retry: false,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

type CheckoutAttemptStoreState = {
  lastCheckoutAttempt: Date | undefined;
  setLastCheckoutAttempt: (date: Date | undefined) => void;
};

export const useCheckoutAttemptStore = create<CheckoutAttemptStoreState>()(
  persist(
    (set) => ({
      lastCheckoutAttempt: undefined,
      setLastCheckoutAttempt: (date) => {
        set({ lastCheckoutAttempt: date });
      },
    }),
    {
      name: 'checkout-attempt-storage',
    },
  ),
);

export const useUserSummaryQuery = () => {
  const { token } = useUserToken();
  const checkoutAttemptStore = useCheckoutAttemptStore();
  return useQuery({
    queryKey: [
      getUserSummaryReq.name,
      token,
      checkoutAttemptStore.lastCheckoutAttempt,
    ],
    queryFn: async () => {
      const lastCheckoutAttempt = checkoutAttemptStore.lastCheckoutAttempt;

      if (lastCheckoutAttempt) {
        if (differenceInMinutes(new Date(), lastCheckoutAttempt) > 2) {
          checkoutAttemptStore.setLastCheckoutAttempt(undefined);
        } else {
          logger.log('Delaying subscription check', lastCheckoutAttempt);
          await delayMs(5 * 1000);
        }
      }

      return await getUserSummaryReq(validTokenGuard(token));
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useIsInvalidSubscription = () => {
  const query = useUserSummaryQuery();
  return (
    query.data?.metering.is_active_subscription === false &&
    !query.data.metering.has_active_stripe_subscription
  );
};
export const useIsValidSubscription = () => {
  const query = useUserSummaryQuery();
  return (
    query.data?.metering.is_active_subscription === true ||
    query.data?.metering.has_active_stripe_subscription === true
  );
};

export const useCancelStripeMutation = () => {
  const { token } = useUserToken();
  const email = useUserStore((state) => state.user)?.email;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      cancelReason,
      likedFeatures,
    }: {
      cancelReason: string;
      likedFeatures: string;
    }) => {
      void postCancelSurveyReq(validTokenGuard(token), {
        email: validTokenGuard(email),
        is_user_deleted: false,
        user_cancel_reason: cancelReason,
        user_liked_features: likedFeatures,
      });
      await cancelStripeSubscriptionReq(validTokenGuard(token));
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries({
        queryKey: [getUserSummaryReq.name],
      });
      logger.log('Stripe subscription canceled');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
export const useModifyStripeSubscriptionMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (stripePriceId: string) => {
      await modifyStripeSubscriptionReq({
        token: validTokenGuard(token),
        stripePriceId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name, token],
      });
      logger.log('Stripe subscription modified');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
export const useModifyStripePaymentMethodMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  return useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        throw new Error('Not initialized yet');
      }

      await elements.submit();

      const paymentMethodResult = await stripe.createPaymentMethod({
        elements,
      });

      if (paymentMethodResult.error) {
        throw new Error(paymentMethodResult.error.message);
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;

      await modifyStripePaymentMethodReq({
        token: validTokenGuard(token),
        paymentMethodId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      logger.log('Stripe payment method modified');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useResubscribeStripeMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const { setClientSecret } = useContextAndErrorIfNull(
    PaymentIntentDialogContext,
  );
  return useMutation({
    mutationFn: async (stripePriceId: string) => {
      return await resubscribeStripeSubscriptionReq({
        token: validTokenGuard(token),
        price_id: stripePriceId,
      });
    },
    onSuccess: (result) => {
      if (result.payment_intent_client_secret) {
        setClientSecret(result.payment_intent_client_secret);
      }
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries({
        queryKey: [getUserSummaryReq.name],
      });
      void queryClient.invalidateQueries({
        queryKey: [getPaymentDetailsReq.name],
      });
      logger.log('Stripe subscription resubscribed');
    },
    onError: (error) => {
      logger.error('Resubscribe error', error);
    },
  });
};

const useReportPaymentIntentMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (paymentIntentId: string) => {
      await successfulPaymentIntentReq({
        token: validTokenGuard(token),
        payment_intent_id: paymentIntentId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries({
        queryKey: [getUserSummaryReq.name],
      });
      void queryClient.invalidateQueries({
        queryKey: [getPaymentDetailsReq.name],
      });
      logger.log('Stripe payment intent reported');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useReportSuccessfulPaymentIntent = () => {
  const mutation = useReportPaymentIntentMutation();
  const { mutate, isPending } = mutation;
  const search: {
    payment_intent?: string;
  } = useSearch({ strict: false });
  useEffect(() => {
    if (search.payment_intent && !isPending) {
      logger.log(
        'Reporting successful payment intent to backend',
        search.payment_intent,
      );
      mutate(search.payment_intent);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [isPending, mutate, search]);
  return mutation;
};

export type OfferedStripePlan = 'monthly-19' | 'yearly-125';

export const offeredPlanToPriceId: Record<OfferedStripePlan, string> = {
  'monthly-19': import.meta.env.VITE_STRIPE_MONTHLY_19_ID as string,
  'yearly-125': import.meta.env.VITE_STRIPE_YEARLY_125_ID as string,
};
export const priceIdToOfferedPlan: Record<string, OfferedStripePlan> = {
  [import.meta.env.VITE_STRIPE_MONTHLY_19_ID as string]: 'monthly-19',
  [import.meta.env.VITE_STRIPE_YEARLY_125_ID as string]: 'yearly-125',
};
