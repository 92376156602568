import type * as TogglePrimitive from '@radix-ui/react-toggle';
import React, { type ComponentProps } from 'react';
import type { ButtonProps } from './button';
import { Button } from './button';
import { Toggle } from './toggle';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';

type TooltipButtonProps = {
  toolTipContent: string | React.ReactNode;
  toolTipEnabled?: boolean;
  toolTipSide?: ComponentProps<typeof TooltipContent>['side'];
  open?: boolean;
} & ButtonProps;

export const TooltipButton = React.forwardRef<
  HTMLButtonElement,
  TooltipButtonProps
>(
  (
    { toolTipContent, toolTipEnabled = true, open, toolTipSide, ...props },
    ref,
  ) => {
    if (!toolTipEnabled) return <Button {...props} ref={ref} />;
    return (
      <Tooltip open={open}>
        <TooltipTrigger asChild>
          <Button {...props} ref={ref} />
        </TooltipTrigger>
        <TooltipContent side={toolTipSide}>{toolTipContent}</TooltipContent>
      </Tooltip>
    );
  },
);
TooltipButton.displayName = 'TooltipButton';

export const TooltipToggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  TogglePrimitive.ToggleProps & {
    toolTipContent: string | React.ReactNode;
  }
>(({ toolTipContent, ...props }, ref) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span>
          {/* Breaks accessibility https://github.com/radix-ui/primitives/discussions/560#discussioncomment-3477536 */}
          <Toggle {...props} ref={ref} />
        </span>
      </TooltipTrigger>
      <TooltipContent>{toolTipContent}</TooltipContent>
    </Tooltip>
  );
});
TooltipToggle.displayName = 'TooltipToggle';
