import AddStickyNoteIcon from '@/assets/icons/add-sticky-note.svg?react';
// import AddToPlaylistIcon from '@/assets/icons/add-to-playlist.svg?react';
import NextOutlineIcon from '@/assets/icons/next-outline.svg?react';
import NextIcon from '@/assets/icons/next.svg?react';
import PlayIcon from '@/assets/icons/play.svg?react';
import PrevOutlineIcon from '@/assets/icons/prev-outline.svg?react';
import PrevIcon from '@/assets/icons/prev.svg?react';
// import ShareIcon from '@/assets/icons/share3.svg?react';

import PauseIcon from '@/assets/icons/pause.svg?react';
import PlaybackSpeedIcon from '@/assets/icons/playbackSpeed.svg?react';
import SectionsIcon from '@/assets/icons/sections.svg?react';
import VolumeIcon from '@/assets/icons/volume.svg?react';
import {
  DEFAULT_TITLE,
  flatChildren,
  setRealAudioDurationMs,
  useAudioTimeSec,
} from '@/lib/audio-utils';
import { useGlobalAudioPlayer } from '@/lib/react-use-audio-player/useGlobalAudioPlayer';
import { cn, formatTime, type ComponentWithClassName } from '@/lib/utils';
import {
  trackLogPlaybackControlsInteraction,
  trackLogSetVolume,
} from '@/services/analytics-service';
import { useListAudioQuery } from '@/services/audio-service';
import { useAudioStore } from '@/stores/audio-store';
import { Link, useMatchRoute } from '@tanstack/react-router';
import type { ComponentProps } from 'react';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'sonner';
import { CoverImgWithBackgroundNew } from './misc/cover-img-with-background';
import { useLoadAudio } from './misc/providers/audio-load-provider';
import Spinner from './misc/spinner';
import { Button } from './ui/button';
import { Slider } from './ui/slider';
import { TooltipButton } from './ui/tooltip-button';

import {
  DropdownMenu,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TooltipToggle } from '@/components/ui/tooltip-button';
import { logger } from '@/lib/logger';
import { useAddAudioNote } from '@/services/notes-service';
import { useLocalPrefsStore } from '@/stores/audio-store';
import { readalongSidebarStateAtom } from '@/stores/read-along-side-bar-atom';
import { useAtom } from 'jotai';
import { VolumeX } from 'lucide-react';
import { useMediaQuery } from 'usehooks-ts';
import PlaybackSpeedDropdown from './audio/playback-speed-dropdown';

export function PlayBar({ className, ...props }: ComponentProps<'footer'>) {
  return (
    <footer
      className={cn(
        'z-50 col-span-2 flex h-[4.5rem] shrink-0 items-center rounded-t-[3rem] bg-background px-8 shadow-2xl shadow-black dark:bg-[#272C3D]',
        className,
      )}
      {...props}
    >
      <AudioPreview />
      <PlaybackControls />
      <SeekBar />
      <PlaybackOptionsButtonGroup />
    </footer>
  );
}

function AudioPreview() {
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);

  const songActive = audioItem?.audioConversionID && chapter?.chapter_id;
  const linkProps: ComponentProps<typeof Link> = songActive
    ? {
        to: '/readalong/$audioId/$chapterId',
        params: {
          audioId: audioItem.audioConversionID,
          chapterId: chapter.chapter_id,
        },
      }
    : {
        to: '/',
      };

  return (
    <Link
      className="hidden w-96 grid-cols-[min-content_1fr] grid-rows-2 items-center gap-x-4 rounded-xl sm:grid"
      {...linkProps}
    >
      <div className="row-span-2 size-12">
        <CoverImgWithBackgroundNew
          coverImg={audioItem?.cover_img}
          title={audioItem?.title ?? DEFAULT_TITLE}
          size="sm"
        />
      </div>
      <p
        className={cn(
          `col-start-2 truncate text-sm font-[700]`,
          (!audioItem || !chapter) && 'row-span-2',
        )}
      >
        {audioItem
          ? (audioItem.title ?? DEFAULT_TITLE)
          : 'Start by uploading a file'}
      </p>
      {audioItem && (
        <p className="col-start-2 truncate text-xs font-[500] text-secondary-foreground">
          {chapter?.title ?? ''}
        </p>
      )}
    </Link>
  );
}

function PlaybackControls() {
  const {
    togglePlayPause: togglePlayPauseState,
    playing,
    isLoading,
  } = useGlobalAudioPlayer();
  const audioItem = useAudioStore((state) => state.audioItem);
  const chapter = useAudioStore((state) => state.chapter);
  const loadAudio = useLoadAudio();
  const audioItemQuery = useListAudioQuery();

  const togglePlayPause = useCallback(() => {
    trackLogPlaybackControlsInteraction({
      interaction: playing ? 'pause' : 'play',
    });
    togglePlayPauseState();
  }, [playing, togglePlayPauseState]);

  useHotkeys(
    'space',
    () => {
      togglePlayPause();
    },
    [togglePlayPause],
  );

  return (
    <>
      <PlaybackControlButton
        Icon={PrevIcon}
        toolTipContent="Previous Document"
        onClick={() => {
          const audioItems = audioItemQuery.data?.data ?? [];
          const currentIdx = audioItems.findIndex(
            (a) => a.audioConversionID === audioItem?.audioConversionID,
          );
          const prevIdx = currentIdx == -1 ? 0 : currentIdx - 1;
          if (prevIdx < 0) return;
          const prevAudio = audioItems[prevIdx];
          if (!prevAudio) return;
          loadAudio(prevAudio);
        }}
      />
      <PlaybackControlButton
        toolTipContent="Previous Section"
        Icon={PrevOutlineIcon}
        onClick={() => {
          trackLogPlaybackControlsInteraction({
            interaction: 'skip_backward',
          });
          if (!audioItem || !chapter) {
            toast.info('No audio loaded');
            return;
          }
          const chapters = flatChildren(audioItem.audioConversion.chapters);
          const chapterIdx = chapters.findIndex(
            (c) => c.chapter_id === chapter.chapter_id,
          );
          if (chapterIdx === 0) {
            toast.info('No previous chapter');
            return;
          }
          loadAudio(audioItem, chapters[chapterIdx - 1]);
        }}
      />

      <Button
        className="size-12 shrink-0 rounded-full p-2"
        onClick={togglePlayPause}
      >
        {isLoading ? (
          <Spinner />
        ) : playing ? (
          <PauseIcon className="size-4 text-white" />
        ) : (
          <PlayIcon className="size-4 text-white" />
        )}
      </Button>
      <PlaybackControlButton
        toolTipContent="Next Section"
        Icon={NextOutlineIcon}
        onClick={() => {
          trackLogPlaybackControlsInteraction({
            interaction: 'skip_forward',
          });
          if (!audioItem || !chapter) {
            toast.info('No audio loaded');
            return;
          }
          const chapters = flatChildren(audioItem.audioConversion.chapters);
          const chapterIdx = chapters.findIndex(
            (c) => c.chapter_id === chapter.chapter_id,
          );
          if (chapterIdx === chapters.length - 1) {
            toast.info('No next chapter');
            return;
          }
          loadAudio(audioItem, chapters[chapterIdx + 1]);
        }}
      />
      <PlaybackControlButton
        toolTipContent="Next Document"
        Icon={NextIcon}
        onClick={() => {
          const audioItems = audioItemQuery.data?.data ?? [];
          const currentIdx = audioItems.findIndex(
            (a) => a.audioConversionID === audioItem?.audioConversionID,
          );
          const nextIdx = currentIdx == -1 ? 0 : currentIdx + 1;
          if (nextIdx >= audioItems.length) return;
          const nextAudio = audioItems[nextIdx];
          if (!nextAudio) return;
          loadAudio(nextAudio);
        }}
      />
    </>
  );
}

function PlaybackControlButton({
  onClick,
  toolTipContent,
  Icon,
}: {
  Icon: ComponentWithClassName;
  toolTipContent: string;
  onClick: () => void;
}) {
  return (
    <TooltipButton
      className="px-2"
      variant="ghost"
      toolTipContent={toolTipContent}
      onClick={onClick}
    >
      <Icon className="text-input" />
    </TooltipButton>
  );
}

export function SeekBar({
  includeTimestamps = true,
  className,
  trackClassName,
}: {
  includeTimestamps?: boolean;
  trackClassName?: string;
  className?: string;
}) {
  const time = useAudioTimeSec();
  const { duration, seek } = useGlobalAudioPlayer();

  useEffect(() => {
    const audioItem = useAudioStore.getState().audioItem;
    const chapter = useAudioStore.getState().chapter;
    if (!audioItem || !chapter) return;
    setRealAudioDurationMs({ audioItem, chapter }, duration * 1000);
  }, [duration]);

  const formattedTime = formatTime(time);
  const formattedDuration = formatTime(duration);

  useHotkeys(
    'left',
    () => {
      seek(time - 5);
    },
    [seek, time],
  );
  useHotkeys(
    'right',
    () => {
      seek(time + 5);
    },
    [seek, time],
  );

  return (
    <>
      {includeTimestamps && (
        <p className="hidden w-12 shrink-0 whitespace-nowrap text-center text-xs font-[500] text-secondary-foreground sm:block">
          {formattedTime}
        </p>
      )}

      <Slider
        trackClassName={trackClassName}
        className={cn('mx-2 h-1.5 basis-full sm:basis-1/2', className)}
        thumbClassName="invisible"
        max={duration}
        min={0}
        onValueChange={(val) => {
          trackLogPlaybackControlsInteraction({
            interaction: 'seek',
          });
          const value = val[0];
          if (value === undefined) return;
          seek(value);
        }}
        value={[time]}
      />
      {includeTimestamps && (
        <p className="hidden w-12 shrink-0 whitespace-nowrap text-center text-xs font-[500] text-secondary-foreground sm:block">
          {formattedDuration}
        </p>
      )}
    </>
  );
}

const useVolume = () => {
  const { setVolume } = useGlobalAudioPlayer();
  const saveVolume = useLocalPrefsStore((state) => state.setVolume);
  const volume = useLocalPrefsStore((state) => state.volume);

  const setAndSaveVolume = useCallback(
    (volume: number) => {
      volume = Math.max(0, Math.min(1, volume));
      setVolume(volume);
      saveVolume(volume);
      trackLogSetVolume({ volume });
    },
    [saveVolume, setVolume],
  );

  return { volume, setAndSaveVolume };
};

function PlaybackOptionsButtonGroup() {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const playbackRate = useLocalPrefsStore((state) => state.playbackRate);
  const { volume, setAndSaveVolume } = useVolume();

  useHotkeys(
    'up',
    () => {
      setAndSaveVolume(volume + 0.1);
    },
    [setAndSaveVolume, volume],
  );
  useHotkeys(
    'down',
    () => {
      setAndSaveVolume(volume - 0.1);
    },
    [setAndSaveVolume, volume],
  );

  return (
    <div className="flex items-center text-secondary-foreground">
      {isSmallScreen ? (
        <Button variant="ghost">Options</Button>
      ) : (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <TooltipButton
                variant="ghost"
                toolTipContent="Speed"
                className="px-2"
              >
                <PlaybackSpeedIcon className="text-input" />
                <p className="min-w-6 text-input">
                  {playbackRate == 1.0 ? '1.0' : playbackRate.toFixed(2)}
                </p>
              </TooltipButton>
            </DropdownMenuTrigger>
            <PlaybackSpeedDropdown />
          </DropdownMenu>
          <ShowSectionsButton />
          <AddAudioNoteButton />
          {/* <AddToPlaylistButton /> */}
          {/* <ShareButton /> */}
          <VolumeToggleButton />
          <Slider
            rangeClassName="bg-foreground rounded-full"
            thumbClassName="invisible"
            className="ml-2 w-28"
            value={[volume]}
            max={1.0}
            min={0}
            step={0.01}
            onValueChange={(val) => {
              const value = val[0];
              if (value === undefined) return;
              setAndSaveVolume(value);
            }}
          />
        </>
      )}
    </div>
  );
}

function ShowSectionsButton() {
  const matchRoute = useMatchRoute();
  const [readalongSidebarState, setReadalongSidebarState] = useAtom(
    readalongSidebarStateAtom,
  );
  const isReadalongPage = !!matchRoute({
    to: '/readalong/$audioId/$chapterId',
    fuzzy: true,
  });
  if (!isReadalongPage) return null;
  return (
    <TooltipButton
      variant="ghost"
      toolTipContent="Sections"
      className="px-3"
      onClick={() => {
        setReadalongSidebarState(
          readalongSidebarState != 'chapters' ? 'chapters' : 'closed',
        );
      }}
    >
      <SectionsIcon className="text-input" />
    </TooltipButton>
  );
}

function AddAudioNoteButton() {
  const { addAudioNote, upsertNoteMutation } = useAddAudioNote();

  return (
    <TooltipButton
      variant="ghost"
      className="px-3"
      onClick={addAudioNote}
      toolTipContent="Add to Highlights"
    >
      {upsertNoteMutation.isPending ? (
        <Spinner />
      ) : (
        <AddStickyNoteIcon className="text-input" />
      )}
    </TooltipButton>
  );
}
// function AddToPlaylistButton() {
//   return (
//     <TooltipButton
//       variant="ghost"
//       toolTipContent="Add to Playlist"
//       className="px-3"
//     >
//       <AddToPlaylistIcon className="text-input" />
//     </TooltipButton>
//   );
// }
// function ShareButton() {
//   return (
//     <TooltipButton variant="ghost" toolTipContent="Share" className="px-3">
//       <ShareIcon className="text-input" />
//     </TooltipButton>
//   );
// }

function VolumeToggleButton() {
  const { volume, setAndSaveVolume } = useVolume();
  return (
    <TooltipToggle
      className="px-2"
      toolTipContent={volume == 0 ? 'Unmute' : 'Mute'}
      pressed={volume == 0}
      onPressedChange={(val) => {
        logger.log('Volume toggled: ', val);
        setAndSaveVolume(val ? 0 : 0.5);
      }}
    >
      {volume == 0 ? (
        <VolumeX className="size-6 text-input" />
      ) : (
        <VolumeIcon className="size-6 text-input" />
      )}
    </TooltipToggle>
  );
}
